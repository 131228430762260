import React from "react";
import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

export const isTokenExpired = (token) => {
  if (!token) {
    console.warn("No token provided to isTokenExpired");
    return true;
  }

  try {
    const decodedToken = jwtDecode(token);
    if (!decodedToken.exp) {
      console.warn("Token does not contain an expiration claim");
      return true;
    }

    const currentTime = Math.floor(Date.now() / 1000);

    if (decodedToken.exp < currentTime) {
      console.log("Token has expired");
      return true;
    }

    const fiveMinutes = 5 * 60;
    if (decodedToken.exp - currentTime < fiveMinutes) {
      console.warn("Token will expire soon");
    }

    return false;
  } catch (error) {
    console.error("Error decoding or validating token:", error);
    return true;
  }
};

export const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem("token");

  if (!token || isTokenExpired(token)) {
    localStorage.removeItem("token");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("email");
    localStorage.removeItem("name");
    return <Navigate to="/login" replace />;
  }

  return children;
};

export const PublicRoute = ({ children }) => {
  const token = localStorage.getItem("token");

  if (token && !isTokenExpired(token)) {
    return <Navigate to="/home" replace />;
  }

  return children;
};
