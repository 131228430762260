export const FETCH_CAMERAS_REQUEST = "FETCH_CAMERAS_REQUEST";
export const FETCH_CAMERAS_SUCCESS = "FETCH_CAMERAS_SUCCESS";
export const FETCH_CAMERAS_FAILURE = "FETCH_CAMERAS_FAILURE";

export const ADD_CAMERA_REQUEST = "ADD_CAMERA_REQUEST";
export const ADD_CAMERA_SUCCESS = "ADD_CAMERA_SUCCESS";
export const ADD_CAMERA_FAILURE = "ADD_CAMERA_FAILURE";

export const DELETE_CAMERA_REQUEST = "DELETE_CAMERA_REQUEST";
export const DELETE_CAMERA_SUCCESS = "DELETE_CAMERA_SUCCESS";
export const DELETE_CAMERA_FAILURE = "DELETE_CAMERA_FAILURE";

export const FETCH_ANOMALIES_REQUEST = "FETCH_ANOMALIES_REQUEST";
export const FETCH_ANOMALIES_SUCCESS = "FETCH_ANOMALIES_SUCCESS";
export const FETCH_ANOMALIES_FAILURE = "FETCH_ANOMALIES_FAILURE";

export const FETCH_OBJECT_DETECTION_REQUEST = "FETCH_OBJECT_DETECTION_REQUEST";
export const FETCH_OBJECT_DETECTION_SUCCESS = "FETCH_OBJECT_DETECTION_SUCCESS";
export const FETCH_OBJECT_DETECTION_FAILURE = "FETCH_OBJECT_DETECTION_FAILURE";

export const FETCH_OVERCROWD_REQUEST = "FETCH_OVERCROWD_REQUEST";
export const FETCH_OVERCROWD_SUCCESS = "FETCH_OVERCROWD_SUCCESS";
export const FETCH_OVERCROWD_FAILURE = "FETCH_OVERCROWD_FAILURE";
