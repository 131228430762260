import React, { useState, useEffect } from "react";
import { BarChart } from "@mui/x-charts";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import styles from "./AnomaliesPerIncident.module.css";
import { useSelector } from "react-redux";
import { handleFetchAnomaliesData } from "../../services/analysis.service";

const StyledSelect = styled(Select)(() => ({
  "& .MuiSelect-select": {
    padding: "0px",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    minHeight: "24px",
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiSvgIcon-root": {
    right: "4px",
    fontSize: "24px",
  },
}));

function AnomaliesPerIncident() {
  const areas = useSelector((state) => state.analysis.areas);
  const anomaliesData = useSelector((state) => state.analysis.anomaliesData);
  const error = useSelector((state) => state.analysis.error);

  const [selectedArea, setSelectedArea] = useState("");
  const [selectedAlert, setSelectedAlert] = useState("All Alerts");
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    handleFetchAnomaliesData();
  }, []);

  useEffect(() => {
    if (areas.length > 0) {
      setSelectedArea(areas[0]);
    }
  }, [areas]);

  useEffect(() => {
    if (areas.length > 0 && Object.keys(anomaliesData).length > 0) {
      processChartData();
    }
  }, [selectedArea, selectedAlert, areas, anomaliesData]);

  const processChartData = () => {
    const filteredAreas =
      selectedArea === "All"
        ? areas.filter((area) => area !== "All")
        : [selectedArea];

    const processedData = filteredAreas.map((area) => {
      const areaData = anomaliesData[area] || {
        low: 0,
        medium: 0,
        critical: 0,
      };
      return {
        place: area,
        critical: areaData.critical,
        moderate: areaData.medium,
        low: areaData.low,
      };
    });

    setChartData(processedData);
  };

  const handleAreaChange = (event) => {
    setSelectedArea(event.target.value);
  };

  const handleAlertChange = (event) => {
    setSelectedAlert(event.target.value);
  };

  const getChartSeries = () => {
    if (selectedAlert === "All Alerts") {
      return [
        {
          data: chartData.map((item) => item.critical),
          label: "Critical Incident",
          color: "#D40511",
        },
        {
          data: chartData.map((item) => item.moderate),
          label: "Moderate Incident",
          color: "#FF5C00",
        },
        {
          data: chartData.map((item) => item.low),
          label: "Low Incident",
          color: "#FF9900",
        },
      ];
    } else {
      const alertType = selectedAlert.toLowerCase().split(" ")[0];
      return [
        {
          data: chartData.map((item) => item[alertType]),
          label: selectedAlert,
          color:
            selectedAlert === "Critical Incident"
              ? "#D40511"
              : selectedAlert === "Moderate Incident"
              ? "#FF5C00"
              : "#FF9900",
        },
      ];
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className={styles.AnomaliesPerIncident}>
      <div className={styles.AnomaliesPerIncidentHeader}>
        <h4>No. of Anomalies by Incident count</h4>
        <div className={styles.chartFilter}>
          <div className={styles.areaDropdown}>
            <StyledSelect
              sx={{ color: "#a3aed0" }}
              value={selectedArea}
              onChange={handleAreaChange}
            >
              {["All", ...areas].map((area) => (
                <MenuItem key={area} value={area} sx={{ fontSize: "16px" }}>
                  {area}
                </MenuItem>
              ))}
            </StyledSelect>
          </div>
          <div className={styles.areaDropdown}>
            <StyledSelect
              sx={{ color: "#a3aed0" }}
              value={selectedAlert}
              onChange={handleAlertChange}
            >
              <MenuItem value={"All Alerts"} sx={{ fontSize: "16px" }}>
                All Alerts
              </MenuItem>
              <MenuItem value={"Critical Incident"} sx={{ fontSize: "16px" }}>
                Critical Incident
              </MenuItem>
              <MenuItem value={"Moderate Incident"} sx={{ fontSize: "16px" }}>
                Moderate Incident
              </MenuItem>
              <MenuItem value={"Low Incident"} sx={{ fontSize: "16px" }}>
                Low Incident
              </MenuItem>
            </StyledSelect>
          </div>
        </div>
      </div>
      <BarChart
        dataset={chartData}
        series={getChartSeries()}
        slotProps={{
          bar: { clipPath: `inset(0px round 12px 12px 0px 0px)` },
          noDataOverlay: {
            message: "No data to display in this Area",
            sx: { fontSize: "20px" },
          },
          legend: {
            direction: "row",
            position: { vertical: "bottom", horizontal: "center" },
            itemMarkWidth: 8,
            itemMarkHeight: 8,
            labelStyle: {
              fontSize: 12,
              fill: "#667085",
            },
          },
        }}
        borderRadius={10}
        height={400}
        xAxis={[
          {
            scaleType: "band",
            data: chartData.map((item) => item.place),
            label: "Places",
            labelStyle: {
              fill: "#667085",
            },
          },
        ]}
        yAxis={[
          {
            label: "No. of Anomalies",
            labelStyle: {
              fill: "#667085",
            },
          },
        ]}
        grid={{
          horizontal: true,
          stroke: "#F2F4F7",
        }}
        leftAxis={{
          disableLine: true,
          disableTicks: true,
        }}
        bottomAxis={{
          disableLine: true,
          disableTicks: true,
        }}
        margin={{ left: 70, right: 10, bottom: 90 }}
        sx={{
          [`.${axisClasses.left} .${axisClasses.label}`]: {
            transform: "translate(-25px, 0)",
          },
          [`.${axisClasses.bottom} .${axisClasses.label}`]: {
            transform: "translate(0, 10px)",
          },
          [`.${axisClasses.root}`]: {
            [`.${axisClasses.tickLabel}`]: {
              fill: "#667085",
            },
          },
        }}
      />
    </div>
  );
}

export default AnomaliesPerIncident;
