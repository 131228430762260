import { useCallback, useEffect, useState } from "react";
import "../cameraDetails/cameraDetails.css";
import addIcon from "../../../icon/add-icon.svg";
import uploadIcon from "../../../icon/uploda-icon.svg";

const CameraDetails = () => {
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [selectedZones, setSelectedZones] = useState({});
  const [showApplyButton, setShowApplyButton] = useState(false);

  const employeeData = [
    {
      id: 1,
      name: "Amarjit",
      employeeId: "XEN0123",
      zone: "603",
      cameras: "603A",
      restrictedAreas: [],
    },
    {
      id: 2,
      name: "Epsa",
      employeeId: "XEN0123",
      zone: "603",
      cameras: "603E",
      restrictedAreas: [],
    },
    {
      id: 3,
      name: "Devansh",
      employeeId: "XEN0123",
      zone: "602",
      cameras: "602A",
      restrictedAreas: [],
    },
    {
      id: 4,
      name: "Jasmeet",
      employeeId: "XEN0123",
      zone: "602",
      cameras: "602B",
      restrictedAreas: [],
    },
    {
      id: 5,
      name: "Manisha",
      employeeId: "XEN0123",
      zone: "603",
      cameras: "603B",
      restrictedAreas: [],
    },
    {
      id: 6,
      name: "Kusum",
      employeeId: "XEN0123",
      zone: "603",
      cameras: "603C",
      restrictedAreas: [],
    },
    {
      id: 7,
      name: "Nikita",
      employeeId: "XEN0123",
      zone: "603",
      cameras: "603D",
      restrictedAreas: [],
    },
    {
      id: 8,
      name: "Aarju",
      employeeId: "XEN0123",
      zone: "602",
      cameras: "602C",
      restrictedAreas: [],
    },
  ];

  const availableZones = ["601", "602", "603"];

  useEffect(() => {
    setFilteredData(employeeData);
  }, []);

  const handleSearch = useCallback(() => {
    if (!searchText.trim()) {
      setFilteredData(employeeData);
      return;
    }

    const searchLower = searchText.toLowerCase().trim();
    const filtered = employeeData.filter(
      (item) =>
        item.name.toLowerCase().includes(searchLower) ||
        item.employeeId.toLowerCase().includes(searchLower)
    );

    setFilteredData(filtered);
  }, [searchText]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  const handleClear = () => {
    setSearchText("");
  };

  const handleZoneSelect = (employeeId, zone) => {
    setSelectedZones((prev) => {
      const currentZones = prev[employeeId] || [];
      const updatedZones = currentZones.includes(zone)
        ? currentZones.filter((z) => z !== zone)
        : [...currentZones, zone];
      return {
        ...prev,
        [employeeId]: updatedZones,
      };
    });
    setShowApplyButton(true);
  };

  const handleApply = (employeeId) => {
    // Handle applying selected zones
    console.log("Applied zones for", employeeId, selectedZones[employeeId]);
    setShowApplyButton(false);
  };

  return (
    <div className="employee-shift-page">
      <div className="employee-page-header">
        <h2>Camera Details</h2>
      </div>

      <div className="search-card-wrapper">
        <div className="search-card">
          <div className="search-inputs-container">
            <div className="search-wrapper">
              <input
                type="text"
                placeholder="Search by Employee ID or Name"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                className="search-input"
              />
              <div className="search-button">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.7071 14.2929L12.0002 10.586C13.0508 9.44743 13.6669 7.99246 13.6669 6.49935C13.6669 2.91598 10.7509 0 7.16754 0C3.58417 0 0.668182 2.91598 0.668182 6.49935C0.668182 10.0827 3.58417 12.9987 7.16754 12.9987C8.66065 12.9987 10.1156 12.3826 11.2542 11.332L14.9611 15.0389C15.1563 15.2341 15.4729 15.2341 15.6681 15.0389C15.8634 14.8436 15.8634 14.5271 15.6681 14.3318L15.7071 14.2929ZM7.16754 11.9987C4.13417 11.9987 1.66818 9.53272 1.66818 6.49935C1.66818 3.46598 4.13417 1 7.16754 1C10.2009 1 12.6669 3.46598 12.6669 6.49935C12.6669 9.53272 10.2009 11.9987 7.16754 11.9987Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </div>
          {searchText && (
            <button onClick={handleClear} className="clear-button">
              Clear <span>×</span>
            </button>
          )}
          <div className="employee-left-header">
            <button className="add-employee">
              <img src={addIcon} alt="add-icon" />
              Add Employee
            </button>
            <button className="add-employee upload-data">
              <img src={uploadIcon} alt="upload" />
              Upload Data
            </button>
          </div>
        </div>
      </div>

      <div className="table-card">
        <div className="camera-list-table">
          <table>
            <thead>
              <tr>
                <th>Employee Name</th>
                <th>Employee ID</th>
                <th>Zone</th>
                <th>Cameras</th>
                <th>Restricted Areas</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item) => (
                <tr key={item.id}>
                  <td>{item.name}</td>
                  <td>{item.employeeId}</td>
                  <td>{item.zone}</td>
                  <td>{item.cameras}</td>
                  <td>
                    <div className="restricted-area-wrapper">
                      <select
                        className="restricted-select"
                        onChange={(e) =>
                          handleZoneSelect(item.employeeId, e.target.value)
                        }
                        value=""
                      >
                        <option value="">Select Zone</option>
                        {availableZones.map((zone) => (
                          <option key={zone} value={zone}>
                            {zone}
                          </option>
                        ))}
                      </select>
                      <div className="selected-zones">
                        {selectedZones[item.employeeId]?.map((zone) => (
                          <div key={zone} className="zone-tag">
                            {zone}
                            <span
                              onClick={() => handleZoneSelect(item.employeeId, zone)}
                              className="remove-zone"
                            >
                              ×
                            </span>
                          </div>
                        ))}
                      </div>
                      {showApplyButton && selectedZones[item.employeeId]?.length > 0 && (
                        <button
                          className="apply-button"
                          onClick={() => handleApply(item.employeeId)}
                        >
                          Apply
                        </button>
                      )}
                    </div>
                  </td>
                  <td>
                    <button className="action-button">⋮</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CameraDetails;
