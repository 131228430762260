import React from "react";
import { PolarArea } from "react-chartjs-2";
import {
  Chart,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import styles from "./LatestMetrics.module.css";
import { handleFetchLatestMetrics } from "../../services/analysis.service";
import { useSelector } from "react-redux";

Chart.register(RadialLinearScale, ArcElement, Tooltip, Legend);

const PolarAreaChart = ({ latestMetrics }) => {
  const data = {
    labels: ["Avg Accuracy", "Avg Precision", "Avg Recall", "Avg F1 Score"],
    datasets: [
      {
        label: "Detection Metrics",
        data: [
          latestMetrics.avg_accuracy,
          latestMetrics.avg_precision,
          latestMetrics.avg_recall,
          latestMetrics.avg_f1_score,
        ],
        backgroundColor: [
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
        ],
        borderColor: [
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return <PolarArea data={data} />;
};

function LatestMetrics() {
  const latestMetrics = useSelector((state) => state.analysis.latestMetrics);

  React.useEffect(() => {
    handleFetchLatestMetrics();
  }, []);

  return (
    <div className={styles.LatestMetrics}>
      <div className={styles.optionContainer}>
        <h4>Latest Metrics</h4>
      </div>
      <div className={styles.chartContainer}>
        {latestMetrics && <PolarAreaChart latestMetrics={latestMetrics} />}
      </div>
    </div>
  );
}

export default LatestMetrics;
