import { BASE_API_V1 } from "../../../utils/config.js";
import axios from 'axios';

const getAuthToken = () => {
  return localStorage.getItem("token");
};

export const getAllEmployees = async () => {
  try {
    const response = await axios.get(`${BASE_API_V1}/employee_details`);
    if (response.data.status === "success") {
      response.data.data.sort((a, b) => 
        a.name.trim().toLowerCase().localeCompare(b.name.trim().toLowerCase())
      );
    }
    return response.data;
  } catch (error) {
    console.error('Error fetching employees:', error);
    throw error;
  }
};

export const updateEmployeeShift = async (employee_id, shift_timing) => {
  try {
    const response = await axios.put(`${BASE_API_V1}/update_shift`, {
      employee_id,
      shift_timing
    });
    return response.data;
  } catch (error) {
    console.error('Error updating shift:', error);
    throw error;
  }
};
