import * as types from "../actionTypes/analysis.type";
import {
  FetchTotalCameras,
  FetchIncidentResponseCount,
  FetchIncidentData,
  FetchAreaList,
  FetchRealtimeAccuracy,
  FetchModelAccuracy,
  FetchAvgIdeal,
  FetchAvgResponsebyHour,
  FetchAnomaliesData,
  FetchDetectionCount,
  FetchTimeSpentData,
  FetchOperationalCount,
  FetchTimeSpentEmployee,
  FetchEmployeeActivityAnalysis,
  FetchEmployeeInteractions,
  FetchLatestMetrics,
} from "../api/analysis.api";

export const fetchModelAccuracy = () => async (dispatch) => {
  dispatch({ type: types.FETCH_MODEL_ACCURACY_REQUEST });
  try {
    const response = await FetchModelAccuracy();
    dispatch({
      type: types.FETCH_MODEL_ACCURACY_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: types.FETCH_MODEL_ACCURACY_FAILURE,
      payload: error.message,
    });
  }
};

export const fetchRealtimeAccuracy = () => async (dispatch) => {
  dispatch({ type: types.FETCH_REALTIME_ACCURACY_REQUEST });
  try {
    const response = await FetchRealtimeAccuracy();
    dispatch({
      type: types.FETCH_REALTIME_ACCURACY_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: types.FETCH_REALTIME_ACCURACY_FAILURE,
      payload: error.message,
    });
  }
};

export const fetchOperationalCount = () => async (dispatch) => {
  dispatch({ type: types.FETCH_OPERATIONAL_COUNT_REQUEST });
  try {
    const response = await FetchOperationalCount();
    dispatch({
      type: types.FETCH_OPERATIONAL_COUNT_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: types.FETCH_OPERATIONAL_COUNT_FAILURE,
      payload: error.message,
    });
  }
};

export const fetchIncidentResponseCount = (days) => async (dispatch) => {
  dispatch({ type: types.FETCH_INCIDENT_RESPONSE_COUNT_REQUEST });
  try {
    const response = await FetchIncidentResponseCount(days);
    dispatch({
      type: types.FETCH_INCIDENT_RESPONSE_COUNT_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: types.FETCH_INCIDENT_RESPONSE_COUNT_FAILURE,
      payload: error.message,
    });
  }
};

export const fetchAreaList = () => async (dispatch) => {
  dispatch({ type: types.FETCH_AREA_LIST_REQUEST });
  try {
    const response = await FetchAreaList();
    dispatch({
      type: types.FETCH_AREA_LIST_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: types.FETCH_AREA_LIST_FAILURE,
      payload: error.message,
    });
  }
};

export const fetchTimeSpentData = (area, known_status) => async (dispatch) => {
  dispatch({ type: types.FETCH_TIME_SPENT_DATA_REQUEST });
  try {
    const response = await FetchTimeSpentData(area, known_status);
    dispatch({
      type: types.FETCH_TIME_SPENT_DATA_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: types.FETCH_TIME_SPENT_DATA_FAILURE,
      payload: error.message,
    });
  }
};

export const fetchTimeSpentEmployee = (area, id, days) => async (dispatch) => {
  dispatch({ type: types.FETCH_TIME_SPENT_EMPLOYEE_REQUEST });
  try {
    const response = await FetchTimeSpentEmployee(area, id, days);
    dispatch({
      type: types.FETCH_TIME_SPENT_EMPLOYEE_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: types.FETCH_TIME_SPENT_EMPLOYEE_FAILURE,
      payload: error.message,
    });
  }
};

export const fetchTotalCameras = () => async (dispatch) => {
  dispatch({ type: types.FETCH_TOTAL_CAMERAS_REQUEST });
  try {
    const response = await FetchTotalCameras();
    dispatch({
      type: types.FETCH_TOTAL_CAMERAS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: types.FETCH_TOTAL_CAMERAS_FAILURE,
      payload: error.message,
    });
  }
};

export const fetchIncidentData = (days) => async (dispatch) => {
  dispatch({ type: types.FETCH_INCIDENT_DATA_REQUEST });
  try {
    const response = await FetchIncidentData(days);
    dispatch({
      type: types.FETCH_INCIDENT_DATA_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: types.FETCH_INCIDENT_DATA_FAILURE,
      payload: error.message,
    });
  }
};

export const fetchAvgResponsebyHour = () => async (dispatch) => {
  dispatch({ type: types.FETCH_AVG_RESPONSE_BY_HOUR_REQUEST });
  try {
    const response = await FetchAvgResponsebyHour();
    dispatch({
      type: types.FETCH_AVG_RESPONSE_BY_HOUR_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: types.FETCH_AVG_RESPONSE_BY_HOUR_FAILURE,
      payload: error.message,
    });
  }
};

export const fetchAnomaliesData = () => async (dispatch) => {
  dispatch({ type: types.FETCH_ANOMALIES_DATA_REQUEST });
  try {
    const response = await FetchAnomaliesData();
    dispatch({
      type: types.FETCH_ANOMALIES_DATA_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: types.FETCH_ANOMALIES_DATA_FAILURE,
      payload: error.message,
    });
  }
};

export const fetchDetectionCount = (days, area) => async (dispatch) => {
  dispatch({ type: types.FETCH_DETECTION_COUNT_REQUEST });
  try {
    const response = await FetchDetectionCount(days, area);
    dispatch({
      type: types.FETCH_DETECTION_COUNT_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: types.FETCH_DETECTION_COUNT_FAILURE,
      payload: error.message,
    });
  }
};

export const fetchAvgIdeal = (employee_id) => async (dispatch) => {
  dispatch({ type: types.FETCH_AVG_IDEAL_REQUEST });
  try {
    const response = await FetchAvgIdeal(employee_id);
    dispatch({
      type: types.FETCH_AVG_IDEAL_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: types.FETCH_AVG_IDEAL_FAILURE,
      payload: error.message,
    });
  }
};

export const fetchEmployeeActivityAnalysis =
  (area, days) => async (dispatch) => {
    dispatch({ type: types.FETCH_EMPLOYEE_ACTIVITY_ANALYSIS_REQUEST });
    try {
      const response = await FetchEmployeeActivityAnalysis(area, days);
      dispatch({
        type: types.FETCH_EMPLOYEE_ACTIVITY_ANALYSIS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: types.FETCH_EMPLOYEE_ACTIVITY_ANALYSIS_FAILURE,
        payload: error.message,
      });
    }
  };

export const fetchEmployeeInteractions = (area, days) => async (dispatch) => {
  dispatch({ type: types.FETCH_EMPLOYEE_INTERACTIONS_REQUEST });
  try {
    const response = await FetchEmployeeInteractions(area, days);
    dispatch({
      type: types.FETCH_EMPLOYEE_INTERACTIONS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: types.FETCH_EMPLOYEE_INTERACTIONS_FAILURE,
      payload: error.message,
    });
  }
};

export const fetchLatestMetrics = () => async (dispatch) => {
  dispatch({ type: types.FETCH_LATEST_METRICS_REQUEST });
  try {
    const response = await FetchLatestMetrics();
    dispatch({
      type: types.FETCH_LATEST_METRICS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: types.FETCH_LATEST_METRICS_FAILURE,
      payload: error.message,
    });
  }
};

// export const fetchAnalysisData = (userRole, days) => async (dispatch) => {
//   dispatch({ type: types.FETCH_ANALYSIS_DATA_REQUEST });
//   try {
//     let data;
//     if (userRole === "admin") {
//       data = await Promise.all([
//         FetchModelAccuracy(),
//         FetchRealtimeAccuracy(),
//         FetchAreaList(),
//         FetchTotalCameras(),
//         FetchIncidentData(days),
//       ]);
//     } else if (userRole === "workspace_admin" || userRole === "user") {
//       data = await Promise.all([
//         FetchIncidentResponseCount(days),
//         FetchRealtimeAccuracy(),
//         FetchAreaList(),
//         FetchTotalCameras(),
//         FetchIncidentData(days),
//       ]);
//     }
//     dispatch({
//       type: types.FETCH_ANALYSIS_DATA_SUCCESS,
//       payload: data,
//       userRole,
//     });
//   } catch (error) {
//     dispatch({
//       type: types.FETCH_ANALYSIS_DATA_FAILURE,
//       payload: error.message,
//     });
//   }
// };
