import { BASE_API_V1 } from "../utils/config";

const getAuthToken = () => {
  return localStorage.getItem("token");
};

class AlertApi {
  constructor() {
    this.baseUrl = BASE_API_V1;
  }

  async fetchAlerts(page, rowsPerPage, selectedOption) {
    const option =
      selectedOption === "All Incident"
        ? "all"
        : selectedOption === "Active Incidents"
        ? "unresolved"
        : "resolved";

    try {
      const response = await fetch(
        `${this.baseUrl}/Incident_overview?page_number=${page}&resolved_type=${option}&rows_per_page=${rowsPerPage}`,
        {
          headers: {
            Authorization: `Bearer ${getAuthToken()}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch alerts data");
      }

      return await response.json();
    } catch (error) {
      console.error("Error fetching alerts:", error);
      throw error;
    }
  }

  async fetchIncidentDetails(caseId, valueType) {
    try {
      const response = await fetch(
        `${this.baseUrl}/Incident_detailed?case_id=${caseId}&value_type=${valueType}`,
        {
          headers: {
            Authorization: `Bearer ${getAuthToken()}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch incident details");
      }

      return await response.json();
    } catch (error) {
      console.error("Error fetching incident details:", error);
      throw error;
    }
  }

  async updateAnomaly(id, valueType, isResolved) {
    const url = `${this.baseUrl}/anomaly/update`;
    const payload = {
      id,
      value_type: valueType,
      is_resolved: isResolved,
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getAuthToken()}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      if (data.detail !== "Anomaly updated successfully") {
        throw new Error("Failed to update anomaly");
      }

      return data;
    } catch (error) {
      console.error("Error updating anomaly:", error);
      throw error;
    }
  }
}

const Api = new AlertApi();
export default Api;
