import React, { useState, useEffect, useMemo } from "react";
import { BarChart } from "@mui/x-charts";
import PieChartView from "../../../Components/PieChartView/PieChartView";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { Grid } from "@mui/material";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CircularProgress from "@mui/material/CircularProgress";
import ThermalGraph from "../../../Components/ThermalGraph/ThermalGraph";
import CpuUsageGraph from "../../../Components/CpuUsageGraph/CpuUsageGraph";
import CameraFeedGraph from "../../../Components/CameraFeedGraph/CameraFeedGraph";
import InfoCards from "../../../Components/InfoCards/InfoCards";
import TotalCameraIMG from "../../../icon/TotalCamera.svg";
import TotalIncidentIMG from "../../../icon/TotalIncident.svg";
import CamerasOperationalIMG from "../../../icon/CamerasOperational.svg";
import CamerasNonOperationalIMG from "../../../icon/CamerasNonOperational.svg";
import DetectionCountGraph from "../../../Components/DetectionCountGraph/DetectionCountGraph.jsx";
import AnomaliesPerIncident from "../../../Components/AnomaliesPerIncident/AnomaliesPerIncident.jsx";
import AvgResponsebyHourGraph from "../../../Components/AvgResponsebyHourGraph/AvgResponsebyHourGraph.jsx";
import AvgEmployeeIdealTime from "../../../Components/AvgEmployeeIdealTime/AvgEmployeeIdealTime.jsx";
import EmployeeActivityAnalysis from "../../../Components/EmployeeActivityAnalysis/EmployeeActivityAnalysis.jsx";
import {
  useUserRole,
  useUserIsLoading,
} from "../../../services/user.service.jsx";
import { useSelector } from "react-redux";
import {
  handleFetchTimeSpentData,
  handleFetchTimeSpentEmployee,
  handleFetchAvgResponsebyHour,
  handleFetchModelAccuracy,
  handleFetchRealtimeAccuracy,
  handleFetchOperationalCount,
  handleFetchAreaList,
  handleFetchTotalCameras,
  handleFetchIncidentData,
  handleFetchIncidentResponseCount,
} from "../../../services/analysis.service.jsx";
import { handleFetchCameras } from "../../../services/imageDetection.service.jsx";
import styles from "./analysis.module.css";
import EmployeeInteractions from "../../../Components/EmployeeInteractions/EmployeeInteractions.jsx";
import LatestMetrics from "../../../Components/LatestMetrics/LatestMetrics.jsx";

const StyledSelect = styled(Select)(() => ({
  "& .MuiSelect-select": {
    padding: "0px",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    minHeight: "24px",
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiSvgIcon-root": {
    right: "4px",
    fontSize: "24px",
  },
}));

const Analysis = () => {
  const userRole = useUserRole();
  const isRoleLoading = useUserIsLoading();

  const analysisState = useSelector((state) => state.analysis);
  const {
    modelAccuracy,
    realtimeAccuracy,
    operationalCount,
    areas,
    totalCameras,
    incidentData,
    incidentResponseCount,
    timeSpentData,
    timeSpentEmployee,
    // error,
  } = analysisState;

  const [selectedArea, setSelectedArea] = useState(areas[0] ? areas[0] : "");
  const [selectDays, setSelectDays] = useState(7);
  const [days, setDays] = useState(7);

  const visibleBars = 7;
  const [startIndex, setStartIndex] = useState(0);
  const visibleData = timeSpentData.slice(startIndex, startIndex + visibleBars);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  useEffect(() => {
    handleFetchCameras();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        handleFetchOperationalCount(),
        handleFetchRealtimeAccuracy(),
        handleFetchAreaList(),
        handleFetchTotalCameras(),
      ]);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (isRoleLoading || !userRole || !days) return;

    const fetchData = async () => {
      try {
        if (userRole === "admin") {
          handleFetchModelAccuracy();
        } else if (userRole === "workspace_admin" || userRole === "user") {
          handleFetchIncidentResponseCount(days);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [userRole, isRoleLoading, days]);

  useEffect(() => {
    handleFetchIncidentData(days);
  }, [days]);

  useEffect(() => {
    if (areas.length > 0) {
      setSelectedArea(areas[0]);
    }
  }, [areas]);

  useEffect(() => {
    if (userRole === "workspace_admin" || userRole === "user")
      handleFetchAvgResponsebyHour();
  }, [userRole]);

  useEffect(() => {
    if (selectedArea && userRole !== "workspace_admin") {
      handleFetchTimeSpentData(selectedArea, selectDays);
    }
  }, [selectedArea, selectDays, userRole]);

  const handleAreaChange = (event) => {
    setSelectedArea(event.target.value);
  };

  const handleFilterChange = (event) => {
    setSelectDays(event.target.value);
  };

  const inforCardData = useMemo(
    () => [
      {
        img: TotalCameraIMG,
        title: "Total Camera",
        count: totalCameras || "-",
      },
      {
        img: TotalIncidentIMG,
        title: "Total incident",
        count: incidentData.total || "-",
      },
      {
        img: CamerasOperationalIMG,
        title: "Cameras Operational",
        count: operationalCount.operational_count || "-",
      },
      {
        img: CamerasNonOperationalIMG,
        title: "Cameras Non-Operational",
        count: operationalCount.non_operational_count,
      },
    ],
    [totalCameras, incidentData.total]
  );

  // Function to export data as CSV
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const pad = (num) => num.toString().padStart(2, "0");

    return `${pad(hours)}:${pad(minutes)}:${pad(remainingSeconds)}`;
  };

  const exportCSV = (data) => {
    const csvRows = [];

    const headers = ["ID", "Name", "Time Spent"];
    csvRows.push(headers.join(","));

    data.forEach((item) => {
      const formattedTime = formatTime(item.timeSpent.toFixed(0));
      const values = [item.id, item.name, formattedTime];
      csvRows.push(values.join(","));
    });

    const csvContent = "data:text/csv;charset=utf-8," + csvRows.join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "employee_time_duration.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleLabelClick = (event, labelInfo) => {
    const clickedEmployee = visibleData[labelInfo.dataIndex];
    if (clickedEmployee) {
      setSelectedEmployee(clickedEmployee);
      setDialogOpen(true);
      handleFetchTimeSpentEmployee(
        selectedArea,
        clickedEmployee.id,
        selectDays
      );
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedEmployee(null);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };

    return date.toLocaleString("en-US", options);
  };

  if (isRoleLoading) {
    return (
      <div className={styles.loaderContainer}>
        <CircularProgress />
      </div>
    );
  }

  if (!userRole) {
    return <div>Unable to load user role. Please try logging in again.</div>;
  }

  // if (error) {
  //   return <div className="Error">Error: {error}</div>;
  // }

  return (
    <div className={styles.AnalysisWrapper}>
      <h2>Analysis</h2>
      <div className={styles.InfoCardsContainer}>
        {inforCardData.map((card, index) => (
          <InfoCards
            key={index}
            ImgSrc={card.img}
            Title={card.title}
            Count={card.count}
          />
        ))}
      </div>
      <div className={styles.SystemAccuracy}>
        <h3>System Accuracy and Compliance</h3>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            {userRole === "admin" ? (
              <>
                <Grid item xs={4}>
                  <PieChartView
                    Title={"Model Accuracy"}
                    DropValue={"Monthly"}
                    PieValue={[
                      {
                        value: modelAccuracy,
                        color: "#007DD6",
                        label: "Model Accuracy",
                      },
                      {
                        value: 100 - modelAccuracy,
                        color: "#007DD633",
                        label: "Remaining data",
                      },
                    ]}
                    numberCount={1}
                  />
                </Grid>
                <Grid item xs={4}>
                  <PieChartView
                    Title={"Incidents Recorded"}
                    DropDown={true}
                    DropValue={"Weekly"}
                    PieValue={
                      incidentData.total === 0
                        ? [
                            { value: 0, color: "#FFCC00", label: "No data" },
                            {
                              value: 100,
                              color: "#FFCC00",
                              label: "Remaining data",
                            },
                          ]
                        : incidentData.percentages
                    }
                    numberCount={3}
                    setDays={setDays}
                  />
                </Grid>
                <Grid item xs={4}>
                  <PieChartView
                    Title={"Real-time accuracy"}
                    DropValue={"Monthly"}
                    PieValue={[
                      {
                        value: realtimeAccuracy,
                        color: "#6202FE",
                        label: "Real-time accuracy",
                      },
                      {
                        value: 100 - realtimeAccuracy,
                        color: "#0022D633",
                        label: "Remaining data",
                      },
                    ]}
                    numberCount={1}
                  />
                </Grid>
              </>
            ) : userRole === "workspace_admin" || userRole === "user" ? (
              <>
                <Grid item xs={4}>
                  <PieChartView
                    Title={"Incidents Recorded"}
                    DropDown={true}
                    DropValue={"Weekly"}
                    PieValue={
                      incidentData.total === 0
                        ? [
                            { value: 0, color: "#FFCC00", label: "No data" },
                            {
                              value: 100,
                              color: "#FFCC00",
                              label: "Remaining data",
                            },
                          ]
                        : incidentData.percentages
                    }
                    numberCount={3}
                    setDays={setDays}
                  />
                </Grid>
                <Grid item xs={4}>
                  <PieChartView
                    Title={"Incidents Response Status"}
                    DropDown={true}
                    DropValue={"Weekly"}
                    PieValue={[
                      {
                        value: incidentResponseCount.resolved,
                        color: "#4AB58E",
                        label: "Resolved Incident",
                      },
                      {
                        value: incidentResponseCount.unresolved,
                        color: "#EE4526",
                        label: "Active Incident",
                      },
                    ]}
                    numberCount={2}
                    setDays={setDays}
                  />
                </Grid>
                <Grid item xs={4}>
                  <PieChartView
                    Title={"Real-time accuracy"}
                    DropValue={"Monthly"}
                    PieValue={[
                      {
                        value: realtimeAccuracy,
                        color: "#6202FE",
                        label: "Real-time accuracy",
                      },
                      {
                        value: 100 - realtimeAccuracy,
                        color: "#0022D633",
                        label: "Remaining data",
                      },
                    ]}
                    numberCount={1}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AvgResponsebyHourGraph />
                </Grid>
                <Grid item xs={7}>
                  <AnomaliesPerIncident />
                </Grid>
                <Grid item xs={5}>
                  <DetectionCountGraph />
                </Grid>
                {userRole === "workspace_admin" && userRole !== "user" && (
                  <Grid item xs={12}>
                    <AvgEmployeeIdealTime />
                  </Grid>
                )}
              </>
            ) : (
              <Grid item xs={8}>
                <p style={{ margin: "0px", fontSize: "16px" }}>
                  Role is undefined
                </p>
              </Grid>
            )}
          </Grid>
        </Box>
      </div>
      {userRole === "user" ? (
        <div className={styles.SystemReliabilityWrapper}>
          <div className={styles.SystemReliabilityHeader}>
            <h4>Employee Total Time Duration</h4>
            <div className={styles.optionContainer}>
              <div className={styles.chartFilter}>
                <div className={styles.areaDropdown}>
                  <StyledSelect
                    sx={{ color: "#a3aed0" }}
                    value={selectedArea}
                    onChange={handleAreaChange}
                  >
                    {areas.map((area) => (
                      <MenuItem
                        key={area}
                        value={area}
                        sx={{ fontSize: "16px" }}
                      >
                        {area}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </div>
                <div className={styles.areaDropdown}>
                  <StyledSelect
                    sx={{ color: "#a3aed0" }}
                    value={selectDays}
                    onChange={handleFilterChange}
                  >
                    <MenuItem value={30} sx={{ fontSize: "16px" }}>
                      Monthly
                    </MenuItem>
                    <MenuItem value={7} sx={{ fontSize: "16px" }}>
                      Weekly
                    </MenuItem>
                    <MenuItem value={1} sx={{ fontSize: "16px" }}>
                      Daily
                    </MenuItem>
                  </StyledSelect>
                </div>
              </div>
              <Button
                variant="outlined"
                size="large"
                onClick={() => exportCSV(timeSpentData)}
                sx={{
                  width: "150px",
                  padding: "6px 0px",
                  border: "1px solid #a3aed0",
                  borderRadius: "12px",
                  fontSize: "14px",
                  color: "#a3aed0",
                  "&:hover": {
                    borderColor: "black",
                    color: "black",
                  },
                }}
              >
                Export as CSV
              </Button>
              <Button
                variant="outlined"
                size="large"
                onClick={() => setStartIndex(startIndex - visibleBars)}
                disabled={startIndex === 0}
                sx={{
                  marginLeft: "20px",
                  padding: "8px 10px",
                  border: "2px solid #a3aed0",
                  borderRadius: "12px",
                  color: "#a3aed0",
                  "&:hover": {
                    border: "2px solid black",
                    color: "black",
                  },
                }}
              >
                <ArrowBackIosIcon sx={{ fontSize: "20px" }} />
              </Button>
              <Button
                variant="outlined"
                size="large"
                onClick={() => setStartIndex(startIndex + visibleBars)}
                disabled={startIndex + visibleBars >= timeSpentData.length}
                sx={{
                  padding: "8px 10px",
                  border: "2px solid #a3aed0",
                  borderRadius: "12px",
                  color: "#a3aed0",
                  "&:hover": {
                    border: "2px solid black",
                    color: "black",
                  },
                }}
              >
                <ArrowForwardIosIcon sx={{ fontSize: "20px" }} />
              </Button>
            </div>
          </div>
          <BarChart
            series={[
              {
                data: visibleData.map((item) => item.timeSpent.toFixed(2)),
                color: "#5971F4",
                valueFormatter: (value) => {
                  const hours = Math.floor(value / 3600)
                    .toString()
                    .padStart(2, "0");
                  const minutes = Math.floor((value % 3600) / 60)
                    .toString()
                    .padStart(2, "0");
                  const seconds = Math.floor(value % 60)
                    .toString()
                    .padStart(2, "0");

                  if (hours === "00") {
                    return `${minutes}:${seconds} min`;
                  } else {
                    return `${hours}:${minutes}:${seconds} hrs`;
                  }
                },
              },
            ]}
            slotProps={{
              bar: { clipPath: `inset(0px round 12px 12px 0px 0px)` },
              noDataOverlay: {
                message: "No data to display in this Area",
                sx: { fontSize: "20px" },
              },
            }}
            height={400}
            title={`Time Spent by Employees in Area ${selectedArea}`}
            // barLabel="value"
            xAxis={[
              {
                scaleType: "band",
                data: visibleData.map((item) => item.name),
              },
            ]}
            yAxis={[
              {
                label: "Time Spent",
                labelStyle: {
                  fill: "#667085",
                },
                valueFormatter: (value) => `${(value / 60).toFixed(1)} min`,
              },
            ]}
            grid={{
              horizontal: true,
              stroke: "#F2F4F7",
            }}
            leftAxis={{
              disableLine: true,
              disableTicks: true,
            }}
            bottomAxis={{
              disableLine: true,
              disableTicks: true,
            }}
            borderRadius={10}
            margin={{ left: 90, right: 0, bottom: 50 }}
            sx={{
              [`.${axisClasses.left} .${axisClasses.label}`]: {
                transform: "translate(-45px, 0)",
              },
              [`.${axisClasses.root}`]: {
                [`.${axisClasses.tickLabel}`]: {
                  fill: "#667085",
                },
              },
            }}
          />
        </div>
      ) : userRole === "admin" ? (
        <>
          <div className={styles.SystemPerformance}>
            <h3>System Performance</h3>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <PieChartView
                    Title={"Model Uptime"}
                    DropValue={"Monthly"}
                    PieValue={[
                      { value: 95, color: "#16458A", label: "Model Uptime" },
                      { value: 5, color: "#A9CCFF" },
                    ]}
                    numberCount={1}
                  />
                </Grid>
                <Grid item xs={8}>
                  <CpuUsageGraph
                    Title={"Resource Utilization (CPU, Memory)"}
                    userRole={userRole}
                    isRoleLoading={isRoleLoading}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CameraFeedGraph Title={"Camera Feed Quality"} />
                </Grid>
                <Grid item xs={6}>
                  <ThermalGraph
                    Title={"Resource Utilization (CPU, GPU, Memory) Heat map"}
                  />
                </Grid>
              </Grid>
            </Box>
          </div>
          <div className={styles.SystemReliability}>
            <h3>Productivity and Efficiency</h3>
            <div className={styles.SystemReliabilityWrapper}>
              <div className={styles.SystemReliabilityHeader}>
                <h4>Employee Total Time Duration</h4>
                <div className={styles.optionContainer}>
                  <div className={styles.chartFilter}>
                    <div className={styles.areaDropdown}>
                      <StyledSelect
                        sx={{ color: "#a3aed0" }}
                        value={selectedArea}
                        onChange={handleAreaChange}
                      >
                        {areas.map((area) => (
                          <MenuItem
                            key={area}
                            value={area}
                            sx={{ fontSize: "16px" }}
                          >
                            {area}
                          </MenuItem>
                        ))}
                      </StyledSelect>
                    </div>
                    <div className={styles.areaDropdown}>
                      <StyledSelect
                        sx={{ color: "#a3aed0" }}
                        value={selectDays}
                        onChange={handleFilterChange}
                      >
                        <MenuItem value={30} sx={{ fontSize: "16px" }}>
                          Monthly
                        </MenuItem>
                        <MenuItem value={7} sx={{ fontSize: "16px" }}>
                          Weekly
                        </MenuItem>
                        <MenuItem value={1} sx={{ fontSize: "16px" }}>
                          Daily
                        </MenuItem>
                      </StyledSelect>
                    </div>
                  </div>
                  <Button
                    variant="outlined"
                    size="large"
                    onClick={() => exportCSV(timeSpentData)}
                    sx={{
                      width: "150px",
                      padding: "6px 0px",
                      border: "1px solid #a3aed0",
                      borderRadius: "12px",
                      fontSize: "14px",
                      color: "#a3aed0",
                      "&:hover": {
                        borderColor: "black",
                        color: "black",
                      },
                    }}
                  >
                    Export as CSV
                  </Button>
                  <Button
                    variant="outlined"
                    size="large"
                    onClick={() => setStartIndex(startIndex - visibleBars)}
                    disabled={startIndex === 0}
                    sx={{
                      marginLeft: "20px",
                      padding: "8px 10px",
                      border: "2px solid #a3aed0",
                      borderRadius: "12px",
                      color: "#a3aed0",
                      "&:hover": {
                        border: "2px solid black",
                        color: "black",
                      },
                    }}
                  >
                    <ArrowBackIosIcon sx={{ fontSize: "20px" }} />
                  </Button>
                  <Button
                    variant="outlined"
                    size="large"
                    onClick={() => setStartIndex(startIndex + visibleBars)}
                    disabled={startIndex + visibleBars >= timeSpentData.length}
                    sx={{
                      padding: "8px 10px",
                      border: "2px solid #a3aed0",
                      borderRadius: "12px",
                      color: "#a3aed0",
                      "&:hover": {
                        border: "2px solid black",
                        color: "black",
                      },
                    }}
                  >
                    <ArrowForwardIosIcon sx={{ fontSize: "20px" }} />
                  </Button>
                </div>
              </div>
              <BarChart
                series={[
                  {
                    data: visibleData.map((item) => item.timeSpent.toFixed(2)),
                    color: "#5971F4",
                    valueFormatter: (value) => {
                      const hours = Math.floor(value / 3600)
                        .toString()
                        .padStart(2, "0");
                      const minutes = Math.floor((value % 3600) / 60)
                        .toString()
                        .padStart(2, "0");
                      const seconds = Math.floor(value % 60)
                        .toString()
                        .padStart(2, "0");

                      if (hours === "00") {
                        return `${minutes}:${seconds} min`;
                      } else {
                        return `${hours}:${minutes}:${seconds} hrs`;
                      }
                    },
                  },
                ]}
                slotProps={{
                  bar: {
                    clipPath: `inset(0px round 12px 12px 0px 0px)`,
                  },
                  noDataOverlay: {
                    message: "No data to display in this Area",
                    sx: { fontSize: "20px" },
                  },
                }}
                onItemClick={handleLabelClick}
                height={400}
                title={`Time Spent by Employees in Area ${selectedArea}`}
                // barLabel="value"
                xAxis={[
                  {
                    scaleType: "band",
                    data: visibleData.map((item) => item.name),
                  },
                ]}
                yAxis={[
                  {
                    label: "Time (in mins)",
                    labelStyle: {
                      fill: "#667085",
                    },
                    valueFormatter: (value) => `${(value / 60).toFixed(1)} min`,
                  },
                ]}
                grid={{
                  horizontal: true,
                  stroke: "#F2F4F7",
                }}
                leftAxis={{
                  disableLine: true,
                  disableTicks: true,
                }}
                bottomAxis={{
                  disableLine: true,
                  disableTicks: true,
                }}
                borderRadius={10}
                margin={{ left: 90, right: 0, bottom: 50 }}
                sx={{
                  [`.${axisClasses.left} .${axisClasses.label}`]: {
                    transform: "translate(-45px, 0)",
                  },
                  [`.${axisClasses.root}`]: {
                    [`.${axisClasses.tickLabel}`]: {
                      fill: "#667085",
                    },
                  },
                }}
              />
            </div>

            <Dialog
              open={dialogOpen}
              onClose={handleCloseDialog}
              PaperProps={{
                sx: {
                  borderRadius: "2rem",
                  padding: "1rem 1.5rem",
                  backgroundColor: "var(--clr-white)",
                  color: "var(--clr-dark)",
                  width: "600px",
                  height: "500px",
                },
              }}
            >
              <DialogTitle
                sx={{
                  padding: "1rem 0.625rem",
                  fontSize: "var(--size-5xl)",
                  fontWeight: "500",
                }}
              >
                Employee Details
              </DialogTitle>
              <DialogContent
                sx={{ padding: "1rem 0.625rem 0.625rem 0.625rem" }}
              >
                {selectedEmployee ? (
                  <>
                    <div className={styles.dialogBox}>
                      <p>Name: {selectedEmployee.name}</p>
                      <p>
                        Time Spent: {selectedEmployee.timeSpent.toFixed(0)} sec
                      </p>
                      <p>ID: {selectedEmployee.id}</p>
                    </div>
                    <div>
                      {timeSpentData !== null &&
                      timeSpentEmployee.length > 0 ? (
                        <>
                          {timeSpentEmployee.map((value, index) => (
                            <div key={index} className={styles.dialogBox}>
                              <p>
                                Time Spent: {value.time_spent.toFixed(0)} sec
                              </p>
                              <p>
                                Detected at: {formatDate(value.detected_at)}
                              </p>
                            </div>
                          ))}
                        </>
                      ) : (
                        <div className={styles.dialogBox}>
                          <p>No Time Spent data found for this Employee</p>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <div className={styles.dialogBox}>
                    <p>No employee selected</p>
                  </div>
                )}
              </DialogContent>
              <DialogActions sx={{ padding: "1rem 0.625rem" }}>
                <CustomButton onClick={handleCloseDialog}>Close</CustomButton>
              </DialogActions>
            </Dialog>

            {/* <Box sx={{ flexGrow: 1, marginTop: "20px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <AvgEmployeeIdealTime />
                  </Grid>
                </Grid>
              </Box> */}
            <Box sx={{ flexGrow: 1, marginTop: "20px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <EmployeeActivityAnalysis />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ flexGrow: 1, marginTop: "20px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <EmployeeInteractions />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ flexGrow: 1, marginTop: "20px" }}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <LatestMetrics />
                </Grid>
                <Grid item xs={8}>
                  <DetectionCountGraph />
                </Grid>
              </Grid>
            </Box>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default React.memo(Analysis);

const CustomButton = styled(Button)(({ theme, selected }) => ({
  color: "var(--clr-dark)",
  border: "1px solid #e0e0e0",
  borderRadius: ".75rem",
  fontSize: "var(--size-3xl)",
  textTransform: "capitalize",
  fontWeight: "500",
  padding: ".5rem 1rem",
  "&:hover": {
    backgroundColor: "#e0e0e0",
  },
  ...(selected && {
    backgroundColor: "var(--clr-dark)",
    color: "var(--clr-white)",
  }),
}));
