import React from "react";
import { useState, useRef, useEffect } from "react";
import { ArrowDropDown } from "@mui/icons-material";
import { Badge, Box, CircularProgress, Grid } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { BASE_API_V1 } from "../../../utils/config.js";
import { toast, ToastContainer } from "react-toastify";
import noPreview from "../../../static/noPreview.svg";
import deleteIcon from "../../../icon/delete_icon.png";
import deleteAlert from "../../../icon/DeleteAlert.svg";
import UnknownIcon from "../../../icon/UNKNOWN.svg";
import "react-toastify/dist/ReactToastify.css";
import "./imageDetection.css";
import styles from "./imageDetection.module.css";
import AnomalyIcon, {
  getPriorityInfo,
} from "../../../Components/ColorIcons/ColorIcons.jsx";

import {
  handleFetchCameras,
  handleAddCamera,
  handleDeleteCamera,
  handleFetchAnomalies,
  handleFetchObjectDetection,
  handleFetchOvercrowd,
} from "../../../services/imageDetection.service.jsx";
import { useSelector } from "react-redux";

const ImageDetection = () => {
  const [cameraID, setCameraID] = useState("");
  const [rtspUrl, setRtspUrl] = useState("");
  const [iframeSrc, setIframeSrc] = useState("");

  const [cameraName, setCameraName] = useState("");
  const [areaName, setAreaName] = useState("");
  const [addingCamera, setAddingCamera] = useState(false);
  const [currentObjectStatus, setCurrentObjectStatus] = useState(null);

  // add camera states
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rtspLink, setRtspLink] = useState("");
  const [addedCameras, setAddedCameras] = useState([]);
  const [restrictedArea, setRestrictedArea] = useState(false);
  const [theft, setTheft] = useState(false);
  const [walking, setWalking] = useState(false);
  const [ppe, setPpe] = useState(false);
  const [currentTheftStatus, setCurrentTheftStatus] = useState(false);
  const [currentObjectBay, setCurrentObjectBay] = useState(false);
  const [currentPpeKit, setCurrentPpeKit] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const options = ["vehicle", "person", "cell phone"];

  // URLs
  const baseUrl = `${BASE_API_V1}/original_feed?`;
  const modelBaseUrl = `${"https://xenonstack-ai-app-ai-backend.lab.neuralcompany.team"}/video?rtsp_link=`;
  const objectDetectionUrl =
    "https://surveillance-backend-v1-ai.lab.neuralcompany.team/video";
  const theftDetectionUrl =
    "https://surveillance-backend-v1-ai.lab.neuralcompany.team/theft_detection";
  const objectBayUrl =
    "https://surveillance-backend-v1-ai.lab.neuralcompany.team/object_detection";
  const ppeKitUrl =
    "https://surveillance-backend-v1-ai.lab.neuralcompany.team/video_ppe";

  const intervalRef = useRef(null);
  const [showIframeLoader, setShowIframeLoader] = useState(false);

  const imageDetectionState = useSelector((state) => state.imageDetection);
  const { cameras, anomalies, objectDetection, overcrowdData, loading, error } =
    imageDetectionState;

  useEffect(() => {
    handleFetchCameras();
  }, []);

  useEffect(() => {
    if (rtspUrl) {
      intervalRef.current = setInterval(() => {
        handleFetchObjectDetection(
          rtspUrl,
          cameraID,
          currentObjectStatus,
          currentTheftStatus,
          currentObjectBay,
          currentPpeKit
        );
        handleFetchAnomalies(rtspUrl);
        handleFetchOvercrowd(areaName, currentObjectStatus);
      }, 10000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [
    rtspUrl,
    cameraID,
    currentObjectStatus,
    currentTheftStatus,
    currentObjectBay,
    currentPpeKit,
    areaName,
  ]);

  useEffect(() => {
    if (cameras.length > 0) {
      const firstCamera = cameras[0];
      setCameraName(firstCamera.area_name);
      setRtspUrl(firstCamera.rtsp_url);
      setCurrentObjectStatus(firstCamera.object_status);
      setCurrentTheftStatus(firstCamera.theft_status);
      setCurrentObjectBay(firstCamera.object_bay_status);
      setCurrentPpeKit(firstCamera.ppe_status);
      setCameraID(firstCamera.id);
      updateIframeSrc(firstCamera);
      handleFetchObjectDetection(
        firstCamera.rtsp_url,
        firstCamera.id,
        firstCamera.object_status,
        firstCamera.theft_status,
        firstCamera.object_bay_status,
        firstCamera.ppe_status
      );
      handleFetchAnomalies(firstCamera.rtsp_url);
      setAddedCameras(cameras);
    }
  }, [cameras]);

  const updateIframeSrc = (camera) => {
    let url;
    if (camera.object_status) {
      const objectClassesParam = camera.object_list
        .map((item) => `object_classes=${encodeURIComponent(item)}`)
        .join("&");
      url = `${objectDetectionUrl}?rtsp_link=${encodeURIComponent(
        camera.rtsp_url
      )}&${objectClassesParam}`;
    } else if (camera.theft_status) {
      url = `${theftDetectionUrl}?rtsp_link=${encodeURIComponent(
        camera.rtsp_url
      )}`;
    } else if (camera.object_bay_status) {
      url = `${objectBayUrl}?rtsp_link=${encodeURIComponent(camera.rtsp_url)}`;
    } else if (camera.ppe_status) {
      url = `${ppeKitUrl}?rtsp_link=${encodeURIComponent(camera.rtsp_url)}`;
    } else {
      url = `${modelBaseUrl}${encodeURIComponent(camera.rtsp_url)}`;
    }
    setIframeSrc(url);
  };

  const handleCheckboxChange = (option) => {
    setSelectedOptions((prevSelected) => {
      const newSelection = prevSelected.includes(option)
        ? prevSelected.filter((item) => item !== option)
        : [...prevSelected, option];

      if (newSelection.length > 0) {
        setTheft(false);
        setWalking(false);
        setPpe(false);
      }

      return newSelection;
    });
  };

  const handleRestrictedAreaToggle = () => {
    setRestrictedArea((prevState) => !prevState);
  };

  const handleTheftToggle = () => {
    setTheft((prevState) => {
      if (!prevState) {
        setWalking(false);
        setPpe(false);
        setSelectedOptions([]);
      }
      return !prevState;
    });
  };

  const handleWalkingToggle = () => {
    setWalking((prevState) => {
      if (!prevState) {
        setTheft(false);
        setPpe(false);
        setSelectedOptions([]);
      }
      return !prevState;
    });
  };

  const handlePpeToggle = () => {
    setPpe((prevState) => {
      if (!prevState) {
        setTheft(false);
        setWalking(false);
        setSelectedOptions([]);
      }
      return !prevState;
    });
  };

  const delayedFetchCameras = () => {
    setTimeout(() => {
      handleFetchCameras();
    }, 800);
  };

  const handleAddCameraClick = async () => {
    setAddingCamera(true);
    if (!rtspLink || !areaName) {
      toast.error("Please provide all required details!");
      setAddingCamera(false);
      return;
    }

    if (!rtspLink.startsWith("rtsp://")) {
      toast.error("Invalid RTSP link format!");
      setAddingCamera(false);
      return;
    }

    const payload = [
      {
        rtsp_url: rtspLink,
        restricted_area: restrictedArea,
        objects: selectedOptions,
        area_name: areaName,
        theft: theft,
        object_walking_bay: walking,
        ppe_kit: ppe,
      },
    ];

    try {
      handleAddCamera(payload);
      toast.success("Camera added successfully!");
      setIsModalVisible(false);
      setRtspLink("");
      setRestrictedArea(false);
      setTheft(false);
      setWalking(false);
      setPpe(false);
      setAreaName("");
      delayedFetchCameras();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setAddingCamera(false);
    }
  };

  const handleDeleteCameraClick = async (cameraId) => {
    try {
      handleDeleteCamera(cameraId);
      console.log("Deleted Camera", cameraId);
      toast.success("Camera deleted successfully!");
      delayedFetchCameras();
    } catch (error) {
      toast.error("Failed to delete camera");
    }
  };

  const handleIframeClick = (camera) => {
    setCameraName(camera.area_name);
    updateIframeSrc(camera);
    setRtspUrl(camera.rtsp_url);
    setCameraID(camera.id);
    setCurrentObjectStatus(camera.object_status);
    setCurrentTheftStatus(camera.theft_status);
    setCurrentObjectBay(camera.object_bay_status);
    setCurrentPpeKit(camera.ppe_status);
    setShowIframeLoader(true);
    setTimeout(() => {
      setShowIframeLoader(false);
    }, 10000);
    handleFetchObjectDetection(
      camera.rtsp_url,
      camera.id,
      camera.object_status,
      camera.theft_status,
      camera.object_bay_status,
      camera.ppe_status
    );
    handleFetchAnomalies(camera.rtsp_url);
  };

  return (
    <div>
      <div className="imageDetectionWrapper">
        <div className="cameraDetails col-12 col-sm-12 col-md-8 col-lg-8">
          <div className="liveStream">
            <div className="liveStreamHeader">
              <p>Live Stream</p>
              <h3>{cameraName}</h3>
            </div>
            <div className="liveVideoWrapper">
              <div className={styles.iframeWrapper}>
                {showIframeLoader ? (
                  <div className={styles.iframeLoader}>
                    <CircularProgress size={60} thickness={4} />
                  </div>
                ) : null}
                {iframeSrc ? (
                  <iframe
                    className="rtsp_video_container"
                    id="rtsp_video_container"
                    src={iframeSrc}
                    title="RTSP Video"
                    frameBorder="0"
                    allowFullScreen
                  ></iframe>
                ) : (
                  <img
                    src={noPreview}
                    alt="computer-vision"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "15px",
                      objectFit: "contain",
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="addedCameras">
            <div className="cameraHeading">
              <h3>Added Camera</h3>
              <div
                className="addCameraWrapper"
                onClick={() => setIsModalVisible(true)}
              >
                <button>
                  <img src="" alt="" />+ Add Camera
                </button>
              </div>
            </div>
            {addedCameras?.length > 0 ? (
              <div className="addedCameraWrapper">
                {addedCameras.map((camera) => (
                  <div className="camera-card" key={camera.id}>
                    <div className="iframe-preview-wrapper">
                      <div
                        className="iframe-container"
                        onClick={() => handleIframeClick(camera)}
                      >
                        <iframe
                          src={
                            baseUrl +
                            `rtsp_url=${camera.rtsp_url}` +
                            `&cam_id=${camera.id}`
                          }
                          title={`Camera Preview: ${camera.area_name}`}
                          style={{
                            width: "100",
                            height: "100",
                            border: "none",
                            objectFit: "contain",
                            display: "block",
                          }}
                          frameBorder="0"
                          allowFullScreen
                        ></iframe>
                        <div className="click-overlay"></div>
                        <div className="camera-info">
                          <p style={{ color: "#fff" }}>{camera.area_name}</p>
                        </div>
                        <img
                          src={deleteIcon}
                          alt="Delete"
                          className="delete-icon"
                          onClick={() => handleDeleteCameraClick(camera.id)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="no-camera-found">No cameras added yet.</div>
            )}
          </div>
        </div>
        <div className="detectedDetails col-12 col-sm-12 col-md-4 col-lg-4">
          <div className="detectedOjects">
            <div className="objectDetectionHeading">
              <h3>Head Count</h3>
              {overcrowdData?.total_count > 0 && (
                <div>
                  <p>Overcrowd detected</p>
                  <p>{overcrowdData?.total_count}</p>
                </div>
              )}
            </div>
            <DetectionList
              objectsDetectedData={objectDetection}
              isLoading={loading}
            />
          </div>
          <div className="anamolyObjects">
            <div className="anomalyHeading">
              <h3>Anomaly Detection</h3>
              <div>
                <Badge
                  badgeContent={loading ? "0" : anomalies?.anomaly_count}
                  sx={{
                    "& .MuiBadge-anchorOriginTopRight	": {
                      fontSize: "10px",
                      backgroundColor: "red",
                      color: "white",
                      fontWeight: "600",
                    },
                  }}
                  overlap="circular"
                  max={999}
                >
                  <NotificationsIcon color="action" sx={{ fontSize: "28px" }} />
                </Badge>
              </div>
            </div>
            <div className="anomalyList">
              <div className="anomalyList">
                {loading ? (
                  <div className="noAnomaly">Loading Anomaly data...</div>
                ) : anomalies?.anomaly_list?.length > 0 ? (
                  anomalies?.anomaly_list?.map((anomaly) => (
                    <div key={anomaly.id} className="anomalyDetected">
                      <AnomalyIcon priority={anomaly.Priority} />
                      <div className={styles.anomalyMessageWrapper}>
                        <div className={styles.anomalyMessageContainer}>
                          <p className={styles.anomalyContent}>
                            {anomaly.content}
                          </p>
                          <p
                            className={styles.anomalyPriority}
                            style={{
                              color: getPriorityInfo(anomaly.Priority).color,
                            }}
                          >
                            {getPriorityInfo(anomaly.Priority).text}
                          </p>
                        </div>
                        <div className={styles.anomalyTimeContainer}>
                          {new Date(
                            new Date(anomaly.timestamp).setHours(
                              new Date(anomaly.timestamp).getHours() + 5,
                              new Date(anomaly.timestamp).getMinutes() + 30
                            )
                          )
                            .toLocaleTimeString("en-US", {
                              hour: "numeric",
                              minute: "numeric",
                              second: "numeric",
                              hour12: true,
                            })
                            .toLowerCase()}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="noAnomaly">No Anomaly Detected!!</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalVisible && (
        <div className="modal-overlay" onClick={() => setIsModalVisible(false)}>
          <div
            className="add-camera-container"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="add-camera-top">
              <h2>Add Your Camera</h2>
              <p>
                Please enter your RTSP link below to add your camera to
                Xenonstack.AI
              </p>
            </div>
            <div className="camera-input-wrapper">
              <div className="camera-title1">
                <p>Camera Title</p>
                <input
                  type="text"
                  placeholder="Cafeteria Camera"
                  value={areaName}
                  onChange={(e) => setAreaName(e.target.value)}
                />
              </div>
              <div className="camera-title1">
                <p>RTSP Link</p>
                <input
                  type="text"
                  placeholder="rtsp://user"
                  value={rtspLink}
                  onChange={(e) => setRtspLink(e.target.value)}
                />
              </div>

              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <div className="restricted-zone">
                      <div className="camera-title">
                        <p>Restricted Area</p>
                      </div>
                      <div className="checkbox-apple">
                        <input
                          className="yep"
                          id="check-apple"
                          type="checkbox"
                          checked={restrictedArea}
                          onChange={handleRestrictedAreaToggle}
                        />
                        <label htmlFor="check-apple"></label>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div
                      className={`restricted-zone ${
                        walking || ppe || selectedOptions.length > 0
                          ? "disabled"
                          : ""
                      }`}
                    >
                      <div className="camera-title">
                        <p>Theft Detection</p>
                      </div>
                      <div className="checkbox-apple">
                        <input
                          className="yep"
                          id="check-theft"
                          type="checkbox"
                          checked={theft}
                          onChange={handleTheftToggle}
                          disabled={
                            walking || ppe || selectedOptions.length > 0
                          }
                        />
                        <label htmlFor="check-theft"></label>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div
                      className={`restricted-zone ${
                        theft || ppe || selectedOptions.length > 0
                          ? "disabled"
                          : ""
                      }`}
                    >
                      <div className="camera-title">
                        <p>Walking Bay Detection</p>
                      </div>
                      <div className="checkbox-apple">
                        <input
                          className="yep"
                          id="check-walkingbay"
                          type="checkbox"
                          checked={walking}
                          onChange={handleWalkingToggle}
                          disabled={theft || ppe || selectedOptions.length > 0}
                        />
                        <label htmlFor="check-walkingbay"></label>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div
                      className={`restricted-zone ${
                        theft || walking || selectedOptions.length > 0
                          ? "disabled"
                          : ""
                      }`}
                    >
                      <div className="camera-title">
                        <p>PPE Detection</p>
                      </div>
                      <div className="checkbox-apple">
                        <input
                          className="yep"
                          id="check-ppe"
                          type="checkbox"
                          checked={ppe}
                          onChange={handlePpeToggle}
                          disabled={
                            theft || walking || selectedOptions.length > 0
                          }
                        />
                        <label htmlFor="check-ppe"></label>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="object-selection">
                      <div
                        className={`dropdown ${
                          theft || walking || ppe ? "disabled" : ""
                        }`}
                      >
                        <button
                          className="dropdown-btn"
                          disabled={theft || walking || ppe}
                        >
                          <div className="selected-options">
                            {selectedOptions.length > 0
                              ? selectedOptions.map((option, index) => (
                                  <span key={index} className="option-tag">
                                    {option}
                                  </span>
                                ))
                              : "Select Objects"}
                          </div>
                          <ArrowDropDown className="dropdown-icon" />
                        </button>
                        <div className="dropdown-content">
                          {options.map((option) => (
                            <label key={option} className="dropdown-item">
                              <input
                                type="checkbox"
                                checked={selectedOptions.includes(option)}
                                onChange={() => handleCheckboxChange(option)}
                                disabled={theft || walking || ppe}
                              />
                              {option}
                            </label>
                          ))}
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </div>
            <div className="add-camera-bottom">
              <div
                className="add-buttom"
                onClick={() => setIsModalVisible(false)}
              >
                <p>Cancel</p>
              </div>
              <div className="add-buttom1" onClick={handleAddCameraClick}>
                {addingCamera ? (
                  <CircularProgress
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      color: "#000",
                    }}
                  />
                ) : (
                  <p>Add Camera</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default ImageDetection;

const DetectionList = ({ objectsDetectedData, isLoading }) => {
  if (isLoading) {
    return <div className="noDetection">Loading detection data...</div>;
  }

  if (!objectsDetectedData || Object.keys(objectsDetectedData).length === 0) {
    return <div className="noDetection">No detection Found!!</div>;
  }

  if (objectsDetectedData.count && objectsDetectedData.theft_data) {
    return (
      <div className="DetectionList">
        <div className={styles.UnknownDetection}>
          <div>
            <img src={UnknownIcon} alt="Unknown-Icon" />
            <h3>Theft Count</h3>
          </div>
          <p>{objectsDetectedData.count}</p>
        </div>
        {Object.entries(objectsDetectedData.theft_data).length > 0 ? (
          Object.entries(objectsDetectedData.theft_data).map(
            ([key, value], index) => (
              <div key={index + 1} className={styles.identifiedObject}>
                <h3>{`Theft ${index + 1}`}</h3>
                <p>{value}</p>
              </div>
            )
          )
        ) : (
          <div className="noDetection">No Theft detected.</div>
        )}
      </div>
    );
  }

  if (objectsDetectedData.ppe_kit) {
    return (
      <div className="DetectionList">
        {objectsDetectedData.ppe_kit.length > 0 ? (
          objectsDetectedData.ppe_kit.map((item, index) => {
            const [key, value] = Object.entries(item)[0];
            return (
              <div key={index} className={styles.identifiedObject}>
                <h3>{key}</h3>
                <p>{value}</p>
              </div>
            );
          })
        ) : (
          <div className="noDetection">No PPE Kit Detected.</div>
        )}
      </div>
    );
  }

  if (objectsDetectedData.temp_obj_bay) {
    return (
      <div className="DetectionList">
        {Object.keys(objectsDetectedData.temp_obj_bay).length > 0 ? (
          Object.entries(objectsDetectedData.temp_obj_bay).map(
            ([key, value], index) => (
              <div key={index} className={styles.identifiedObject}>
                <h3>{key}</h3>
                <p>{value}</p>
              </div>
            )
          )
        ) : (
          <div className="noDetection">No Objects Detected in Bay.</div>
        )}
      </div>
    );
  }

  if (objectsDetectedData.object_count >= 0) {
    return (
      <div className="DetectionList">
        <div className={styles.UnknownDetection}>
          <div>
            <img src={UnknownIcon} alt="Unknown-Icon" />
            <h3>Object Detected</h3>
          </div>
          <p>{objectsDetectedData.object_count}</p>
        </div>
        {Object.entries(objectsDetectedData.data).length > 0 ? (
          Object.entries(objectsDetectedData.data).map(([key, value]) => (
            <div key={key} className={styles.identifiedObject}>
              <h3>{key}</h3>
              <p>{value}</p>
            </div>
          ))
        ) : (
          <div className="noDetection">No object is found.</div>
        )}
      </div>
    );
  }

  const { Total_identified, unknown, known_count } = objectsDetectedData;

  if (!Total_identified && !unknown && !known_count) {
    return <div className="noDetection">Empty Detected Data</div>;
  }

  return (
    <div className="DetectionList">
      <div className={styles.UnknownDetection}>
        <div>
          <img src={UnknownIcon} alt="Unknown-Icon" />
          <h3>{unknown.name}</h3>
        </div>
        <p>{unknown.unknown_count}</p>
      </div>
      <div className={styles.identifiedCount}>
        <p>
          Total Persons Identified : <strong>{known_count}</strong>
        </p>
      </div>
      {Total_identified && Total_identified.length > 0 ? (
        Total_identified.map(({ name, emp_id, face_image }, index) => (
          <div key={index} className={styles.identifiedPerson}>
            <img
              src={`data:image/jpeg;base64,${face_image}`}
              alt="face-image"
            />
            <div>
              <h3>{name}</h3>
              <p>{emp_id}</p>
            </div>
          </div>
        ))
      ) : (
        <div className="noDetection">No identified persons found.</div>
      )}
    </div>
  );
};
