import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ProtectedRoute, PublicRoute } from "./Routes/Routes";
import RootRedirect from "./Routes/RootRedirect";
import { Provider } from "react-redux";
import { store } from "./store";
import Login from "./layout/Authentication/Login";
import Signup from "./layout/Authentication/Signup";
import Home from "./layout/pages/home/home";
import Analysis from "./layout/pages/analysis/analysis";
import Alert from "./layout/pages/alert/alert";
import ImageDetection from "./layout/pages/imageDetection/imageDetection";
import "./App.css";
import Layout from "./layout/ui/Layout";
import Error from "./layout/ui/Error";
import EmployeeShift from "./layout/pages/employeeShift/employeeShift";
import CameraDetails from "./layout/pages/cameraDetails/cameraDetails";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: <RootRedirect />,
      },
      {
        path: "login",
        element: (
          <PublicRoute>
            <Login />
          </PublicRoute>
        ),
      },
      {
        path: "signup",
        element: (
          <PublicRoute>
            <Signup />
          </PublicRoute>
        ),
      },
      {
        path: "/",
        element: (
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        ),
        children: [
          {
            path: "home",
            element: <ImageDetection />,
          },
          {
            path: "alert",
            element: <Alert />,
          },
          {
            path: "analysis",
            element: <Analysis />,
          },
          {
            path: "employee-shift",
            element: <EmployeeShift />,
          },
          {
            path: "camera-details",
            element: <CameraDetails />,
          },
        ],
      },
    ],
  },
]);

function App() {
  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
}

export default App;
