import React, { useState, useEffect } from "react";
import { BarChart } from "@mui/x-charts";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import styles from "./DetectionCountGraph.module.css";
import { useSelector } from "react-redux";
import { handleFetchDetectionCount } from "../../services/analysis.service";

const StyledSelect = styled(Select)(() => ({
  "& .MuiSelect-select": {
    padding: "0px",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    minHeight: "24px",
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiSvgIcon-root": {
    right: "4px",
    fontSize: "24px",
  },
}));

function DetectionCountGraph() {
  const [selectedArea, setSelectedArea] = useState("");
  const [selectDays, setSelectDays] = useState(7);

  const areas = useSelector((state) => state.analysis.areas);
  const detectionCount = useSelector((state) => state.analysis.detectionCount);
  const error = useSelector((state) => state.analysis.error);

  useEffect(() => {
    if (areas.length > 0) {
      setSelectedArea(areas[0]);
    }
  }, [areas]);

  useEffect(() => {
    handleFetchDetectionCount(selectDays, selectedArea);
  }, [selectDays, selectedArea]);

  const handleAreaChange = (event) => {
    setSelectedArea(event.target.value);
  };

  const handleFilterChange = (event) => {
    setSelectDays(event.target.value);
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  const chartData = Object.entries(detectionCount).map(([key, value]) => ({
    name: key,
    count: value,
  }));

  return (
    <div className={styles.DetectionCountGraph}>
      <div className={styles.DetectionCountGraphHeader}>
        <h4>Detection Count</h4>
        <div className={styles.chartFilter}>
          <div className={styles.areaDropdown}>
            <StyledSelect
              sx={{ color: "#a3aed0", textTransform: "capitalize" }}
              value={selectedArea}
              onChange={handleAreaChange}
            >
              {["all", ...areas].map((area) => (
                <MenuItem
                  key={area}
                  value={area}
                  sx={{ fontSize: "16px", textTransform: "capitalize" }}
                >
                  {area}
                </MenuItem>
              ))}
            </StyledSelect>
          </div>
          <div className={styles.areaDropdown}>
            <StyledSelect
              sx={{ color: "#a3aed0" }}
              value={selectDays}
              onChange={handleFilterChange}
            >
              <MenuItem value={30} sx={{ fontSize: "16px" }}>
                Monthly
              </MenuItem>
              <MenuItem value={7} sx={{ fontSize: "16px" }}>
                Weekly
              </MenuItem>
              <MenuItem value={1} sx={{ fontSize: "16px" }}>
                Daily
              </MenuItem>
            </StyledSelect>
          </div>
        </div>
      </div>
      <BarChart
        dataset={chartData}
        series={[
          {
            dataKey: "count",
            color: "#5971F4",
          },
        ]}
        slotProps={{
          bar: { clipPath: `inset(0px round 0px 12px 12px 0px)` },
          noDataOverlay: {
            message: "No data to display in this Area",
            sx: { fontSize: "20px" },
          },
        }}
        borderRadius={10}
        height={400}
        layout="horizontal"
        // barLabel="value"
        yAxis={[
          {
            scaleType: "band",
            dataKey: "name",
            label: "Detected Objects",
            labelStyle: {
              fill: "#667085",
            },
          },
        ]}
        xAxis={[
          {
            label: "Count",
            labelStyle: {
              fill: "#667085",
            },
          },
        ]}
        grid={{
          horizontal: false,
          vertical: true,
          stroke: "#F2F4F7",
        }}
        leftAxis={{
          disableLine: true,
          disableTicks: true,
        }}
        bottomAxis={{
          disableLine: true,
          disableTicks: true,
        }}
        margin={{ left: 120, right: 10 }}
        sx={{
          [`.${axisClasses.left} .${axisClasses.label}`]: {
            transform: "translate(-80px, 0)",
          },
          [`.${axisClasses.root}`]: {
            [`.${axisClasses.tickLabel}`]: {
              fill: "#667085",
            },
          },
        }}
      />
    </div>
  );
}

export default DetectionCountGraph;
