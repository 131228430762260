import { store } from "../store";
import {
  fetchCameras,
  addCamera,
  deleteCamera,
  fetchAnomalies,
  fetchObjectDetection,
  fetchOvercrowd,
} from "../actions/imageDetection.action";

export const handleFetchCameras = () => {
  return store.dispatch(fetchCameras());
};

export const handleAddCamera = (payload) => {
  return store.dispatch(addCamera(payload));
};

export const handleDeleteCamera = (cameraId) => {
  return store.dispatch(deleteCamera(cameraId));
};

export const handleFetchAnomalies = (rtspUrl) => {
  return store.dispatch(fetchAnomalies(rtspUrl));
};

export const handleFetchObjectDetection = (
  rtspUrl,
  cameraId,
  status,
  theftStatus,
  objectBay,
  ppeKit
) => {
  return store.dispatch(
    fetchObjectDetection(
      rtspUrl,
      cameraId,
      status,
      theftStatus,
      objectBay,
      ppeKit
    )
  );
};

export const handleFetchOvercrowd = (area, objectStatus) => {
  return store.dispatch(fetchOvercrowd(area, objectStatus));
};
