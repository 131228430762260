import {
  FETCH_CAMERAS_REQUEST,
  FETCH_CAMERAS_SUCCESS,
  FETCH_CAMERAS_FAILURE,
  ADD_CAMERA_REQUEST,
  ADD_CAMERA_SUCCESS,
  ADD_CAMERA_FAILURE,
  DELETE_CAMERA_REQUEST,
  DELETE_CAMERA_SUCCESS,
  DELETE_CAMERA_FAILURE,
  FETCH_ANOMALIES_REQUEST,
  FETCH_ANOMALIES_SUCCESS,
  FETCH_ANOMALIES_FAILURE,
  FETCH_OBJECT_DETECTION_REQUEST,
  FETCH_OBJECT_DETECTION_SUCCESS,
  FETCH_OBJECT_DETECTION_FAILURE,
  FETCH_OVERCROWD_REQUEST,
  FETCH_OVERCROWD_SUCCESS,
  FETCH_OVERCROWD_FAILURE,
} from "../actionTypes/imageDetection.type";
import {
  AddCamera,
  AnomaliesDetection,
  DeleteCamera,
  FetchCameras,
  FetchOverCrowd,
  ObjectDetection,
} from "../api/imageDetection.api";

export const fetchCameras = () => async (dispatch) => {
  dispatch({ type: FETCH_CAMERAS_REQUEST });
  try {
    const response = await FetchCameras();
    dispatch({ type: FETCH_CAMERAS_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: FETCH_CAMERAS_FAILURE, payload: error.message });
  }
};

export const addCamera = (payload) => async (dispatch) => {
  dispatch({ type: ADD_CAMERA_REQUEST });
  try {
    const response = await AddCamera(payload);
    dispatch({ type: ADD_CAMERA_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: ADD_CAMERA_FAILURE, payload: error.message });
  }
};

export const deleteCamera = (cameraId) => async (dispatch) => {
  dispatch({ type: DELETE_CAMERA_REQUEST });
  try {
    const response = await DeleteCamera(cameraId);
    dispatch({ type: DELETE_CAMERA_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: DELETE_CAMERA_FAILURE, payload: error.message });
  }
};

export const fetchAnomalies = (rtspUrl) => async (dispatch) => {
  dispatch({ type: FETCH_ANOMALIES_REQUEST });
  try {
    const response = await AnomaliesDetection(rtspUrl);
    dispatch({ type: FETCH_ANOMALIES_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: FETCH_ANOMALIES_FAILURE, payload: error.message });
  }
};

export const fetchObjectDetection =
  (rtspUrl, cameraId, status, theftStatus, objectBay, ppeKit) =>
  async (dispatch) => {
    dispatch({ type: FETCH_OBJECT_DETECTION_REQUEST });
    try {
      const response = await ObjectDetection(
        rtspUrl,
        cameraId,
        status,
        theftStatus,
        objectBay,
        ppeKit
      );
      dispatch({ type: FETCH_OBJECT_DETECTION_SUCCESS, payload: response });
    } catch (error) {
      dispatch({
        type: FETCH_OBJECT_DETECTION_FAILURE,
        payload: error.message,
      });
    }
  };

export const fetchOvercrowd = (area, objectStatus) => async (dispatch) => {
  dispatch({ type: FETCH_OVERCROWD_REQUEST });
  try {
    const response = await FetchOverCrowd(area, objectStatus);
    dispatch({ type: FETCH_OVERCROWD_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: FETCH_OVERCROWD_FAILURE, payload: error.message });
  }
};
