import { useCallback, useEffect, useState } from "react";
import "./employeeShift.css";
import addIcon from "../../../icon/add-icon.svg";
import uploadIcon from "../../../icon/uploda-icon.svg";
import avtarIcon from "../../../icon/avtar-icon.svg";
import { getAllEmployees, updateEmployeeShift } from "./employeeShift.api";

const EmployeeShift = () => {
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [editMode, setEditMode] = useState(null);
  const [editData, setEditData] = useState({
    department: "",
    shift_timing: "",
  });

  // Fetch employee data
  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        setLoading(true);
        const response = await getAllEmployees();
        if (response.status === "success") {
          setEmployeeData(response.data);
          setFilteredData(response.data);
        }
      } catch (err) {
        setError("Failed to fetch employee data");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchEmployees();
  }, []);

  const handleSearch = useCallback(() => {
    if (!searchText.trim()) {
      const sortedData = [...employeeData].sort((a, b) => 
        a.name.trim().toLowerCase().localeCompare(b.name.trim().toLowerCase())
      );
      setFilteredData(sortedData);
      return;
    }

    const searchLower = searchText.toLowerCase().trim();
    const filtered = employeeData
      .filter(
        (item) =>
          item.name.trim().toLowerCase().includes(searchLower) ||
          item.employee_id.toLowerCase().includes(searchLower)
      )
      .sort((a, b) => a.name.trim().toLowerCase().localeCompare(b.name.trim().toLowerCase()));

    setFilteredData(filtered);
  }, [searchText, employeeData]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  const handleClear = () => {
    setSearchText("");
  };

  const handleActionClick = (id) => {
    setActiveDropdown(activeDropdown === id ? null : id);
    setEditMode(null);
  };

  const handleEdit = (id) => {
    setEditMode(id);
    const employee = filteredData.find((item) => item.id === id);
    setEditData({
      department: employee.department,
      shift_timing: employee.shift_timing,
    });
    setActiveDropdown(null);
  };

  const handleRemoveUser = (id) => {
    setActiveDropdown(null);
    // Add remove user logic here
  };

  const handleSaveEdit = async (id) => {
    try {
      const employee = filteredData.find((item) => item.id === id);
      // Extract only the start time from the shift timing input
      const shiftStartTime = editData.shift_timing.split(" ")[0];
      
      await updateEmployeeShift(employee.employee_id, shiftStartTime);
      
      // Refresh the employee list to get updated data
      const response = await getAllEmployees();
      if (response.status === "success") {
        setEmployeeData(response.data);
        setFilteredData(response.data);
      }
      
      setEditMode(null);
      setEditData({ department: "", shift_timing: "" });
    } catch (error) {
      console.error("Failed to update shift:", error);
      // You might want to show an error message to the user here
    }
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="employee-shift-page">
      <div className="employee-page-header">
        <h2>Employee Shift</h2>
      </div>

      <div className="search-card-wrapper">
        <div className="search-card">
          <div className="search-inputs-container">
            <div className="search-wrapper">
              <input
                type="text"
                placeholder="Search by Employee ID or Name"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                className="search-input"
              />
              <div className="search-button">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.7071 14.2929L12.0002 10.586C13.0508 9.44743 13.6669 7.99246 13.6669 6.49935C13.6669 2.91598 10.7509 0 7.16754 0C3.58417 0 0.668182 2.91598 0.668182 6.49935C0.668182 10.0827 3.58417 12.9987 7.16754 12.9987C8.66065 12.9987 10.1156 12.3826 11.2542 11.332L14.9611 15.0389C15.1563 15.2341 15.4729 15.2341 15.6681 15.0389C15.8634 14.8436 15.8634 14.5271 15.6681 14.3318L15.7071 14.2929ZM7.16754 11.9987C4.13417 11.9987 1.66818 9.53272 1.66818 6.49935C1.66818 3.46598 4.13417 1 7.16754 1C10.2009 1 12.6669 3.46598 12.6669 6.49935C12.6669 9.53272 10.2009 11.9987 7.16754 11.9987Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </div>
          {searchText && (
            <button onClick={handleClear} className="clear-button">
              Clear <span>×</span>
            </button>
          )}
          <div className="employee-left-header">
            <button className="add-employee">
              <img src={addIcon} alt="add-icon" />
              Add Employee
            </button>
            <button className="add-employee upload-data">
              <img src={uploadIcon} alt="upload" />
              Upload Data
            </button>
          </div>
        </div>
      </div>

      <div className="table-card">
        <div className="camera-list-table">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Department</th>
                <th>Employee ID</th>
                <th>Shift</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item) => (
                <tr key={item.id}>
                  <td>
                    <div className="employee-name">
                      <img src={avtarIcon} alt="avtar-icon" />
                      {item.name}
                    </div>
                  </td>
                  <td>
                    {editMode === item.id ? (
                      <input
                        type="text"
                        value={editData.department}
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            department: e.target.value,
                          })
                        }
                        className="edit-input"
                      />
                    ) : (
                      item.department
                    )}
                  </td>
                  <td>{item.employee_id}</td>
                  <td>
                    {editMode === item.id ? (
                      <input
                        type="text"
                        value={editData.shift_timing}
                        onChange={(e) =>
                          setEditData({
                            ...editData,
                            shift_timing: e.target.value,
                          })
                        }
                        placeholder="HH:mm"
                        className="edit-input"
                      />
                    ) : (
                      item.shift_timing
                    )}
                  </td>
                  <td>
                    {editMode === item.id ? (
                      <div className="edit-actions">
                        <button
                          onClick={() => handleSaveEdit(item.id)}
                          className="save-button"
                        >
                          Save
                        </button>
                      </div>
                    ) : (
                      <div className="action-cell">
                        <button
                          onClick={() => handleActionClick(item.id)}
                          className="action-button"
                        >
                          ⋮
                        </button>
                        {activeDropdown === item.id && (
                          <div className="action-dropdown">
                            <div
                              className="action-item"
                              onClick={() => handleEdit(item.id)}
                            >
                              Edit
                            </div>
                            <div
                              className="action-item"
                              onClick={() => handleRemoveUser(item.id)}
                            >
                              Remove User
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default EmployeeShift;
