import React, { useState } from "react";
import { CircularProgress } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import styles from "./ChatBot.module.css";

const ChatBot = ({ open, onClose }) => {
  const [messages, setMessages] = useState([
    { text: "Hi 👋 How can I help you?", isUser: false },
  ]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSend = async () => {
    if (input.trim() === "") return;

    const newMessage = { text: input, isUser: true };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setInput("");
    setIsLoading(true);

    try {
      const response = await fetch(
        `https://xenonstack-ai-app-ai-backend.lab.neuralcompany.team/query_sql?query=${input}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
        }
      );

      if (!response.ok) throw new Error("API request failed");

      const data = await response.json();
      const botResponse = {
        text: data.result,
        isUser: false,
        tablesUsed: data.tables_used,
      };
      setMessages((prevMessages) => [...prevMessages, botResponse]);
    } catch (error) {
      console.error("Error fetching chatbot response:", error);
      const errorMessage = {
        text: "Sorry, I encountered an error. Please try again.",
        isUser: false,
      };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  const resetChat = () => {
    setMessages([{ text: "Hi 👋 How can I help you?", isUser: false }]);
    setInput("");
  };

  return (
    <div className={`${styles.chatBotContainer} ${open ? styles.open : ""}`}>
      <div className={styles.chatHeader}>
        <div>
          <Avatar
            sx={{
              bgcolor: "#16458A",
              width: 56,
              height: 56,
              fontSize: "var(--size-5xl)",
            }}
          >
            AI
          </Avatar>
          <div>
            <h3>Chat With</h3>
            <h2>Gen AI</h2>
          </div>
        </div>
        <ReplayRoundedIcon
          sx={{ fontSize: "var(--size-6xl)", cursor: "pointer" }}
          onClick={resetChat}
        />
      </div>
      <div className={styles.chatMessages}>
        {messages.map((message, index) => (
          <div
            key={index}
            className={`${styles.messageContainer} ${
              message.isUser ? styles.userMessage : styles.botMessage
            }`}
          >
            <div className={styles.message}>{message.text}</div>
          </div>
        ))}
        {isLoading && (
          <div className={`${styles.messageContainer} ${styles.botMessage}`}>
            <div className={styles.message}>
              <CircularProgress size={20} />
            </div>
          </div>
        )}
      </div>
      <div className={styles.chatInputContainer}>
        <input
          type="text"
          className={styles.chatInput}
          placeholder="Enter your query ..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && handleSend()}
        />
      </div>
      <ArrowForwardIosRoundedIcon
        onClick={onClose}
        sx={{
          width: "65px",
          height: "65px",
          fontSize: "var(--size-6xl)",
          borderRadius: "50%",
          position: "absolute",
          bottom: "20px",
          right: "-25px",
          padding: "12px",
          color: "#4C9AFF",
          background: "#D2DDFF",
          border: "1px solid #C4C4C4",
          boxShadow: "0px 2px 15px 0px #00000033",
          cursor: "pointer",
        }}
      />
    </div>
  );
};

export default ChatBot;
