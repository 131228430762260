import React from "react";
import { Navigate } from "react-router-dom";
import { isTokenExpired } from "./Routes";

const RootRedirect = () => {
  const token = localStorage.getItem("token");

  if (token && !isTokenExpired(token)) {
    return <Navigate to="/home" replace />;
  } else {
    return <Navigate to="/login" replace />;
  }
};

export default RootRedirect;
