import {
  FETCH_CAMERAS_REQUEST,
  FETCH_CAMERAS_SUCCESS,
  FETCH_CAMERAS_FAILURE,
  ADD_CAMERA_REQUEST,
  ADD_CAMERA_SUCCESS,
  ADD_CAMERA_FAILURE,
  DELETE_CAMERA_REQUEST,
  DELETE_CAMERA_SUCCESS,
  DELETE_CAMERA_FAILURE,
  FETCH_ANOMALIES_REQUEST,
  FETCH_ANOMALIES_SUCCESS,
  FETCH_ANOMALIES_FAILURE,
  FETCH_OBJECT_DETECTION_REQUEST,
  FETCH_OBJECT_DETECTION_SUCCESS,
  FETCH_OBJECT_DETECTION_FAILURE,
  FETCH_OVERCROWD_REQUEST,
  FETCH_OVERCROWD_SUCCESS,
  FETCH_OVERCROWD_FAILURE,
} from "../actionTypes/imageDetection.type";

const initialState = {
  cameras: [],
  anomalies: [],
  objectDetection: {},
  overcrowd: {},
  loading: false,
  error: null,
};

export default function imageDetectionReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CAMERAS_REQUEST:
    case ADD_CAMERA_REQUEST:
    case DELETE_CAMERA_REQUEST:
    case FETCH_ANOMALIES_REQUEST:
    case FETCH_OBJECT_DETECTION_REQUEST:
    case FETCH_OVERCROWD_REQUEST:
      return { ...state, loading: true, error: null };

    case FETCH_CAMERAS_SUCCESS:
      return { ...state, loading: false, cameras: action.payload };

    case ADD_CAMERA_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case DELETE_CAMERA_SUCCESS:
      return {
        ...state,
        loading: false,
        cameras: state.cameras.filter(
          (camera) => camera.id !== action.payload.id
        ),
      };

    case FETCH_ANOMALIES_SUCCESS:
      return { ...state, loading: false, anomalies: action.payload };

    case FETCH_OBJECT_DETECTION_SUCCESS:
      return { ...state, loading: false, objectDetection: action.payload };

    case FETCH_OVERCROWD_SUCCESS:
      return { ...state, loading: false, overcrowd: action.payload };

    case FETCH_CAMERAS_FAILURE:
    case ADD_CAMERA_FAILURE:
    case DELETE_CAMERA_FAILURE:
    case FETCH_ANOMALIES_FAILURE:
    case FETCH_OBJECT_DETECTION_FAILURE:
    case FETCH_OVERCROWD_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
}
