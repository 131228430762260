import { createStore, applyMiddleware, combineReducers } from "redux";
import { thunk } from "redux-thunk";
import userReducer from "./reducers/user.reducer";
import alertReducer from "./reducers/alert.reducer";
import analysisReducer from "./reducers/analysis.reducer";
import imageDetectionReducer from "./reducers/imageDetection.reducer";

const rootReducer = combineReducers({
  user: userReducer,
  alert: alertReducer,
  analysis: analysisReducer,
  imageDetection: imageDetectionReducer,
});

export const store = createStore(rootReducer, applyMiddleware(thunk));
