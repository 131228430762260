import { store } from "../store";
import {
  fetchAnalysisData,
  fetchAnomaliesData,
  fetchAreaList,
  fetchAvgIdeal,
  fetchAvgResponsebyHour,
  fetchDetectionCount,
  fetchEmployeeActivityAnalysis,
  fetchEmployeeInteractions,
  fetchIncidentData,
  fetchIncidentResponseCount,
  fetchLatestMetrics,
  fetchModelAccuracy,
  fetchOperationalCount,
  fetchRealtimeAccuracy,
  fetchTimeSpentData,
  fetchTimeSpentEmployee,
  fetchTotalCameras,
} from "../actions/analysis.action";

export const handleFetchModelAccuracy = () => {
  return store.dispatch(fetchModelAccuracy());
};

export const handleFetchRealtimeAccuracy = () => {
  return store.dispatch(fetchRealtimeAccuracy());
};

export const handleFetchOperationalCount = () => {
  return store.dispatch(fetchOperationalCount());
};

export const handleFetchIncidentResponseCount = (days) => {
  return store.dispatch(fetchIncidentResponseCount(days));
};

export const handleFetchAreaList = () => {
  return store.dispatch(fetchAreaList());
};

export const handleFetchTimeSpentData = (area, known_status) => {
  return store.dispatch(fetchTimeSpentData(area, known_status));
};

export const handleFetchTimeSpentEmployee = (area, id, days) => {
  return store.dispatch(fetchTimeSpentEmployee(area, id, days));
};

export const handleFetchTotalCameras = () => {
  return store.dispatch(fetchTotalCameras());
};

export const handleFetchIncidentData = (days) => {
  return store.dispatch(fetchIncidentData(days));
};

export const handleFetchAvgResponsebyHour = () => {
  return store.dispatch(fetchAvgResponsebyHour());
};

export const handleFetchAnomaliesData = () => {
  return store.dispatch(fetchAnomaliesData());
};

export const handleFetchDetectionCount = (days, area) => {
  return store.dispatch(fetchDetectionCount(days, area));
};

export const handleFetchAvgIdeal = (employee_id) => {
  return store.dispatch(fetchAvgIdeal(employee_id));
};

export const handleFetchEmployeeActivityAnalysis = (area, days) => {
  return store.dispatch(fetchEmployeeActivityAnalysis(area, days));
};

export const handleFetchEmployeeInteractions = (area, days) => {
  return store.dispatch(fetchEmployeeInteractions(area, days));
};

export const handleFetchLatestMetrics = () => {
  return store.dispatch(fetchLatestMetrics());
};

// export const handleFetchAnalysisData = (userRole, days) => {
//   return store.dispatch(fetchAnalysisData(userRole, days));
// };
