import React, { useState, useEffect } from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import styles from "./CpuUsageGraph.module.css";
import { BASE_API_V1 } from "../../utils/config";

const CpuUsageGraph = ({ Title, userRole, isRoleLoading }) => {
  const [data, setData] = useState([]);

  const fetchCpuUsage = async () => {
    try {
      const response = await fetch(`${BASE_API_V1}/cpu_memory`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      return {
        cpuUsage: Number(result.CPU_usage),
        memoryUsage: Number(result.memory),
      };
    } catch (error) {
      console.error("Error fetching CPU usage:", error);
      return null;
    }
  };

  useEffect(() => {
    let interval;

    const updateData = async () => {
      if (!isRoleLoading && userRole !== "workspace_admin") {
        const usage = await fetchCpuUsage();
        if (usage !== null) {
          const newDataPoint = {
            time: new Date().toLocaleTimeString(),
            cpuUsage: usage.cpuUsage,
            memoryUsage: usage.memoryUsage,
          };
          setData((prevData) => [...prevData, newDataPoint].slice(-30));
        }
      }
    };

    if (!isRoleLoading && userRole !== "workspace_admin") {
      updateData();
      interval = setInterval(updateData, 2000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [userRole, isRoleLoading]);

  if (isRoleLoading || userRole === "workspace_admin") {
    return null;
  }

  return (
    <div className={styles.CpuUsageGraph}>
      <h2>{Title}</h2>
      <LineChart
        dataset={data}
        xAxis={[
          {
            dataKey: "time",
            scaleType: "band",
            label: "Time",
            labelStyle: {
              fill: "#667085",
            },
          },
        ]}
        yAxis={[
          {
            label: "CPU Usage (%)",
            labelStyle: {
              fill: "#667085",
            },
          },
        ]}
        series={[
          {
            dataKey: "cpuUsage",
            label: "CPU Usage",
            area: true,
            color: "#16458A",
            showMark: false,
          },
          {
            dataKey: "memoryUsage",
            label: "Memory Usage",
            area: true,
            color: "#F24E1E",
            showMark: false,
          },
        ]}
        slotProps={{
          legend: {
            direction: "row",
            position: { vertical: "top", horizontal: "right" },
            itemMarkWidth: 8,
            itemMarkHeight: 8,
            labelStyle: {
              fontSize: 12,
              fill: "#667085",
            },
          },
        }}
        grid={{
          horizontal: true,
          stroke: "#F2F4F7",
        }}
        leftAxis={{
          disableLine: true,
          disableTicks: true,
        }}
        bottomAxis={{
          disableLine: true,
          disableTicks: true,
        }}
        height={300}
        margin={{ left: 50, right: 0 }}
        sx={{
          ".MuiAreaElement-root": {
            fill: "url(#gradient)",
          },
          [`.${axisClasses.left} .${axisClasses.label}`]: {
            transform: "translate(-10px, 0)",
          },
          [`.${axisClasses.root}`]: {
            [`.${axisClasses.tickLabel}`]: {
              fill: "#667085",
            },
          },
        }}
      />
      {/* Define the gradient */}
      <svg width="0" height="0">
        <defs>
          <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#16458A" stopOpacity={0.2} />
            <stop offset="100%" stopColor="#7F56D900" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="memoryGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#F24E1E" stopOpacity={0.2} />
            <stop offset="100%" stopColor="#F24E1E" stopOpacity={0} />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default CpuUsageGraph;
