// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmployeeActivityAnalysis_AvgEmployeeIdealTime__puxdb {
  width: 100%;
  background-color: #ffffff;
  border-radius: 22px;
  padding: 20px 30px;
  box-shadow: 0px 2px 12px 0px #536fcb24;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  height: 100%;
}

.EmployeeActivityAnalysis_AvgEmployeeIdealTime__puxdb h4 {
  font-size: 14px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #000000;
  margin: 0;
}

.EmployeeActivityAnalysis_AvgEmployeeIdealTime__puxdb p {
  font-size: 14px;
  text-align: center;
  color: #000000;
  margin: 0;
}

.EmployeeActivityAnalysis_optionContainer__QQZj9 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.EmployeeActivityAnalysis_chartFilter__H7Ft4 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.EmployeeActivityAnalysis_areaDropdown__RNL98 {
  padding: 10px;
  border-radius: 5px;
  border: none !important;
  font-weight: 600;
  font-size: 14px;
  color: #5776d8;
}
`, "",{"version":3,"sources":["webpack://./src/Components/EmployeeActivityAnalysis/EmployeeActivityAnalysis.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yBAAyB;EACzB,mBAAmB;EACnB,kBAAkB;EAClB,sCAAsC;EACtC,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,uBAAuB;EACvB,cAAc;EACd,SAAS;AACX;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,cAAc;EACd,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,uBAAuB;EACvB,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB","sourcesContent":[".AvgEmployeeIdealTime {\n  width: 100%;\n  background-color: #ffffff;\n  border-radius: 22px;\n  padding: 20px 30px;\n  box-shadow: 0px 2px 12px 0px #536fcb24;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  overflow: hidden;\n  height: 100%;\n}\n\n.AvgEmployeeIdealTime h4 {\n  font-size: 14px;\n  font-weight: 700;\n  line-height: 28px;\n  letter-spacing: -0.02em;\n  color: #000000;\n  margin: 0;\n}\n\n.AvgEmployeeIdealTime p {\n  font-size: 14px;\n  text-align: center;\n  color: #000000;\n  margin: 0;\n}\n\n.optionContainer {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.chartFilter {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n}\n.areaDropdown {\n  padding: 10px;\n  border-radius: 5px;\n  border: none !important;\n  font-weight: 600;\n  font-size: 14px;\n  color: #5776d8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AvgEmployeeIdealTime": `EmployeeActivityAnalysis_AvgEmployeeIdealTime__puxdb`,
	"optionContainer": `EmployeeActivityAnalysis_optionContainer__QQZj9`,
	"chartFilter": `EmployeeActivityAnalysis_chartFilter__H7Ft4`,
	"areaDropdown": `EmployeeActivityAnalysis_areaDropdown__RNL98`
};
export default ___CSS_LOADER_EXPORT___;
